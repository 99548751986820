<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card mt-3">
          <div class="card-header">
            <h3>Credits</h3>
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
              <th>Invoice #</th>
              <th>Date</th>
              <th>Days Open</th>
              <th>Invoice Amount</th>
              <th>Payment Amount</th>
              <th>Balance Due</th>
              </thead>
              <tbody>
              <tr v-for="credit in accountCredits" :key="credit">
                <td>
                  <router-link v-if="!credit.Invnum.toString().startsWith('5000')" :to="{ name: 'InvoiceDetails', params: { invoiceid: credit.Invnum }}">{{credit.Invnum}}</router-link>
                  <span v-else>{{credit.Invnum}}</span>
                </td>
                <td>{{convertDate(credit.TranDate)}}</td>
                <td>{{credit.days_open}}</td>
                <td>${{this.formatCurrency(credit.invamt)}}</td>
                <td>${{this.formatCurrency(credit.payamt)}}</td>
                <td>${{this.formatCurrency(credit.balance)}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCredits",
  data() {
    return {
      accountCredits:[]
    }
  },
  methods:{
    convertDate(dateString){
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // Months are zero-based
      let day = date.getDate();

      return month + "/" + day + "/" + year;
    }
  },
  mounted() {
    this.$store
        .dispatch("orders/getAccountCredits")
        .then((rez) => {
          this.accountCredits = rez.data
        });
  }
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        cartData: [],
        totalCartItems: 0
    },
    getters: {
        getCart(state){
            return state.cartData
        },
        getTotalCartItems(state){
            return state.totalCartItems
        },
    },
    actions: {
        completeOrder({commit}){
            return new Promise((resolve, reject) => {
                let data = {}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/orders',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        if(response.data.orderNumber) {
                            commit('setCartData',[])
                        }
                        resolve(response.data)
                    }).catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getCartData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/getCart',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setCartData',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        addToCart({state,dispatch},{Code,cartdata}){

            return new Promise((resolve, reject) => {
                state.cartData.push({Code, ...cartdata});
                dispatch('updateCartData',state.cartData).then(() => {
                    resolve()
                }).catch(() => {
                    reject({'err':true})
                })
            })
        },
        updateCartData({commit},cartdata){
            return new Promise((resolve, reject) => {
                let data = { cartdata }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/updateCart',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setCartData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        }
    },
    mutations: {
        setCartData(state, cartData){
            state.totalCartItems = cartData.length
            state.cartData = !cartData ? [] : cartData
        },

    }
}
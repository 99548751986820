<template>
  <div class="hold-transition login-page">
    <div class="login-box content mt-3 mb-3">
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="card card-outline card-primary">
            <div class="card-body" v-show="SentPR">
              <p class="alert alert-success" role="alert">Sent Password Reset</p>
              <br/>
              <p class="mt-3 mb-1">
                <router-link :to="{ name: 'Login'}">Login</router-link>
              </p>
            </div>
            <div class="card-body" v-show="notFound">
              <p class="alert alert-warning" role="alert">Email not found please contact support</p>
              <br/>
              <p class="mt-3 mb-1">
                <router-link :to="{ name: 'Login'}">Login</router-link>
              </p>
            </div>
            <div class="card-body" v-show="sendError">
              <p class="alert alert-warning" role="alert">There was a error please contact support</p>
            </div>
            <div class="card-body" v-show="!SentPR && !notFound">
              <p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
              <p class="alert alert-danger" role="alert" v-show="v$.form.email.$error">Email format incorrect</p>
              <form @submit.prevent="submitForm" autocomplete="off">
                <div class="input-group mb-3">
                  <input type="email" v-model="form.email" class="form-control" placeholder="Email">
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="bi bi-envelope"></span>k
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary btn-block">Request new password</button>
                  </div>
                  <!-- /.col -->
                </div>
              </form>
              <p class="mt-3 mb-1">
                <router-link :to="{ name: 'Login'}">Back to Login</router-link>
              </p>
            </div>
            <!-- /.login-card-body -->
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, required} from '@vuelidate/validators'
import {mapGetters} from "vuex";

export default {
  name: "ForgotPassword",
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      msg: '',
      SentPR: false,
      notFound: false,
      sendError: false,
      form: {
        email: null,
      }
    }
  },
  validations() {
    return {
      form: {
        email: {required, email}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    async submitForm() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/forgotEmailCheck', this.form.email)
          .then(async res => {
            if(res.data.status === 'not found') {
              this.loading = false
              this.notFound = true
              this.SentPR = false;
            }else if(res.data.message === 'Send Error'){
              this.loading = false
              this.sendError = true
              this.SentPR = false;
            }else {
              this.SentPR = true;
            }
          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
  }, mounted() {

  },
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>
<template>
  <div class="container">
    <div class="row">
      <h4 class="fw-bold mb-3 mt-4 pt-2">Documents</h4>
      <div class="col-xs-12 col-lg-6">
        <!-- <div v-for="(cat, key) in category" :key="key"> -->
        <div v-for="cOrder in categoryOrder" :key="cOrder">
          <h5 class="mt-4"><b>{{ category[cOrder] }}</b></h5>
          <p v-if="!pdfList[cOrder]" class="mb-5">There are currently no items to display.</p>
          <table v-else class="table table-striped table-bordered mb-5">
            <thead style="background-color: #FAE565;">
            <tr>
              <th scope="col">Document Name</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="pdf in pdfList[cOrder]" :key="pdf.id">
              <td><a href="#" @click.prevent="dlPdf(pdf.id)">{{ pdf.name }}</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <iframe id="DLframe" :src="iframeSrc" style="width: 0; height: 0;" frameborder="0"></iframe>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";


export default defineComponent({
  name: "PdfList",
  data() {
    return {
      iframeSrc: '',
      loader: "dots",
      category: {1: 'Promos', 2: 'Updates', 3: 'Discontinued Items'},
      categoryOrder: [2,3,1]
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      pdfList: "Accounts/getPdfList",
    }),
  },
  methods:{
    dlPdf(id){
      window.open(process.env.VUE_APP_API_SERVER+'/api/pdfDownload?active=1&dlid='+id);
      //this.iframeSrc = process.env.VUE_APP_API_SERVER+'/api/pdfDownload?active=1&dlid='+id
    }
  },
  mounted() {
    this.$store.dispatch('Accounts/pdfList').then(() => {
      //this.loader.hide()
    })
  }
})
</script>


<style scoped>

</style>
<template>
  <!-- Modal -->
  <div class="modal fade" id="backOrderModal" tabindex="-1" aria-labelledby="backOrderModalLabel" aria-hidden="true" data-mdb-backdrop="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="backOrderModalLabel">Back Order</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>This item is back-ordered. Do you still want to proceed?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary" @click.prevent="yesBO">Yes</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "backorderDetails",
  props: ['detailImg'],
  data() {
    return this.initialState();
  },
  methods: {
    yesBO(){
      this.$emit('boRespond')
    },
    initialState() {
      return {

      }
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
<template>


        <div class="row">
          <input
              ref="input"
              type="file"
              name="image"
              accept="image/*"
              @change="setWaitImage"
          />
          <div class="col-md-6 mx-auto text-center">
            <h5>Crop your image</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mx-auto text-center">
            <section class="cropper-area" v-if="stepNumber === 2">
              <div class="img-cropper">
                <vue-cropper
                    ref="cropper"
                    :src="imgSrc"
                    drag-mode="move"
                    :view-mode="1"
                    :movable="true"
                    :crop-box-movable="true"
                    :crop-box-resizable="true"
                    :minContainerHeight="500"
                    :minContainerWidth="500"
                    :minCanvasWidth="500"
                    :minCanvasHeight="500"
                    :cropMove="testme"
                    :strict="true"
                >
                </vue-cropper>
              </div>

              <button class="btn btn-secondary mt-2 mb-2 text-center" type="button" @click.prevent="cropImage"
                      style="width: 140px;"><strong>Crop</strong></button>

            </section>
          </div>
          <div class="col-12">

          </div>
        </div>

</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Compressor from 'compressorjs';

export default {
  name: "cropperControl",
  components: {
    VueCropper,
  },
  props: {
    selectedFilters: Object,
    stepNumber: Number
  },
  data() {
    return {
      showCrop: false,
      width: 0,
      height: 0,
      testme: '',
      imgSrc: '',
      cropImg: '',
      data: null,
    }
  },
  emits: {
    setUserImage: null,
    updateStep: null,
    setNewImage: null
  },
  watch: {
    selectedFilters: {
      handler: function (newVal) {
        if (newVal.fWidth > 0 && newVal.fHeight > 0) {
          this.showCrop = true
          this.setCropBoxData(newVal)
        }
      },
      deep: true
    }
  },
  methods: {
    cropImage() {
      let imgDataU = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.cropImg = imgDataU;
      this.$emit('setUserImage', imgDataU)
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setCropBoxData(newVal) {
      let data = {};

      data.width = (parseInt(newVal.fWidth) * 6);
      data.height = (parseInt(newVal.fHeight) * 6);
      this.$refs.cropper.setCropBoxData(data);
      //this.$refs.cropper.relativeZoom(1000);

      this.width = newVal.fWidth
      this.height = newVal.fHeight

      this.$refs.cropper.setAspectRatio(parseInt(newVal.fWidth) / parseInt(newVal.fHeight))
      //this.$refs.cropper.setAspectRatio = 40/20

    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setAfterData() {
      let nd = {
        "x": 120,
        "y": 63,
        "width": (this.selectedFilters.fWidth * 6),
        "height": (this.selectedFilters.fHeight * 6),
        "rotate": 0,
        "scaleX": 1,
        "scaleY": 1
      }
      this.$refs.cropper.setData(nd);
      this.setCropBoxData({'fWidth': this.selectedFilters.fWidth, 'fHeight': this.selectedFilters.fHeight})
    },
    async setWaitImage(e) {
      await this.$emit('updateStep', 2)
      await this.$emit('setNewImage', e)
      this.setImage(e);
    },
    setImage(e) {
      let file = '';
      if (e instanceof File) {
        file = e;
      } else if (e.target.files === undefined) {
        file = e.dataTransfer.files[0];
      } else {
        file = e.target.files[0]
      }

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      let self = this
      let crop = this.$refs.cropper
      new Compressor(file, {
        quality: 0.6,
        width: 600,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(reader) {

          var readFile = new FileReader();
          readFile.readAsDataURL(reader);
          readFile.onload = function () {
            this.imgSrc = readFile.result;
            crop.replace(readFile.result);

            setTimeout(function () {
              self.setAfterData();
            }, 300)
          }

        },
        error(err) {
          console.log(err.message);
        },
      });

    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 600px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview2 {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
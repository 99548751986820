<template>
  <div>
    <div class="devClass" v-if="dev">
      <div class="devClassTxt">Staging Site</div>
    </div>
    <div class="devClassLong" v-if="dev"></div>
    <!--NAVIGATION START-->
    <nav class="navbar navbar-expand-lg">
      <div class="container-xl">
        <a class="navbar-brand" href="#" title="AMPFrames">
          <img src="@/assets/images/amoflogoxs.png" class="img-fluid"/>
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 main-nav-container">
            <li class="nav-item">
              <router-link
                  to="/"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
              >
                <a :href="href" @click="navigate" class="nav-link active">
                  Home
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/Shop"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
              >
                <a :href="href" @click="navigate" class="nav-link active">
                  Shop
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/FrameBuilder"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
              >
                <a :href="href" @click="navigate" class="nav-link">
                  Frame Creator
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/whoWeAre"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
              >
                <a :href="href" @click="navigate" class="nav-link">
                  Who We Are
                </a>
              </router-link>
            </li>

            <li class="nav-item" v-if="!isLoggedin">
              <router-link
                  to="/whereToBuy"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
              >
                <a :href="href" @click="navigate" class="nav-link">
                  Where to buy
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/login"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
                  v-if="!isLoggedin"
              >
                <a :href="href" @click="navigate" class="nav-link"> Sign In </a>
              </router-link>
              <!--
                <a
                        :href="href"
                        @click="logout"
                        class="nav-link"
                        v-if="isLoggedin"
                >
                    Logout
                </a>
              -->

              <router-link
                  to="/pdflist"
                  v-slot="{ href, navigate }"
                  style="text-decoration: none"
                  v-if="isLoggedin"
              >
                <a :href="href" @click="navigate" class="nav-link">
                  Updates
                </a>
              </router-link>


            </li>
          </ul>

          <form class="d-flex" role="search">
            <div class="adtlBox">
              <VueMultiselect
                  v-model="selectedSearch"
                  :select-label="''"
                  :options="options"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :hide-selected="true"
                  :internal-search="false"
                  :allow-empty="true"
                  :block-keys="['Tab']"
                  open-direction="bottom"
                  placeholder="Search by name or Item #"
                  @search-change="asyncSearch"
                  label="Code"
                  track-by="Code"
                  id="sbname"
                  @keydown.enter="preventEnter"
                  :loading="isLoading"
              >
                <template v-slot:option="{ option }">
                  <b>{{ option.Code }}</b>
                  <br/>
                  <span>{{ option.Description }}</span>
                </template>
                <template #noResult>
                  No products found
                </template>
              </VueMultiselect>

              <div class="adtlInnerBox" v-show="adtlShow">
                <div class="row adtlSF">
                  <div class="col-6">
                    <div
                        id="searchLeft"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div
                            class="form-check"
                            v-for="type in (availableFilters.type ? availableFilters.type.slice(0, 3) : [])"
                            :key="type.value"
                        >
                          <input
                              class="form-check-input"
                              type="radio"
                              name="flt_leftsearch"
                              v-model="adtlSFType"
                              :value="[type.value]"
                              :id="'defaultCheckSF_'+type.value"
                          />
                          <label
                              class="form-check-label"
                              :for="'defaultCheckSF_'+type.value"
                          >
                            {{ type.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                        id="searchRight"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div
                            class="form-check"
                            v-for="type in (availableFilters.type ? availableFilters.type.slice(3, 6) : [])"
                            :key="type.value"
                        >
                          <input
                              class="form-check-input"
                              type="radio"
                              name="flt_rightsearch"
                              v-model="adtlSFType"
                              :value="[type.value]"
                              :id="'defaultCheckSF_'+type.value"
                          />
                          <label
                              class="form-check-label"
                              :for="'defaultCheckSF_'+type.value"
                          >
                            {{ type.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row adtlSFLbl">
                  <div class="col-8">
                    <div>Additional Filters</div>
                  </div>
                  <div class="col-4 text-end">
                    <div>
                      <a href="#" @click.prevent="clearSF">Clear</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>

          <div class="btn-group ms-2" v-if="isLoggedin">
            <button
                type="button"
                class="btn btn-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
              <i class="bi bi-person-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/Admin/userSettings">My Account</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/Admin/userListOrders">Orders Invoiced</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/Admin/userListOrders/pending">Orders Pending</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/Admin/userListOrders/payments">Payments</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/Admin/Credits">Credits</router-link>
              </li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <li><a class="dropdown-item" href="#" @click.prevent="logout">Sign Out</a></li>
            </ul>
          </div>

          <a class="ms-2 me-2" href="/Cart" v-if="isLoggedin && !noPurchase">
            <button type="button" class="btn btn-link position-relative">
              <i class="bi bi-cart-fill fs-5"></i>
              <span
                  v-show="totalCartItems > 0"
                  class="position-absolute top-0 translate-middle badge rounded-pill bg-warning z-3"
                  style="left:23px; top:2px !important;"
              >
                {{ totalCartItems }}
                <span class="visually-hidden">items in cart</span>
              </span>
            </button>
          </a>
        </div>
      </div>
    </nav>

    <router-view/>

    <!--FOOTER-->

    <footer class="bg-dark main-footer">
      <div class="container">
        <div class="row align-items-start">
          <div class="col">
            <ul>
              <li>
                <a
                    href="https://www.instagram.com/ampfframes/"
                    class="rounded-circle d-block text-center mb-4"
                    target="_blank"
                    style="
                    background-color: white;
                    width: 50px;
                    height: 50px;
                    padding-top: 6px;
                  "
                >
                  <i
                      class="bi bi-instagram"
                      style="font-size: 1.7rem; color: #2b8c83"
                  ></i>
                </a>
                <a
                    href="https://www.facebook.com/people/AMPF-Moulding/100092358672420/?mibextid=LQQJ4d"
                    class="rounded-circle d-block text-center mb-4"
                    target="_blank"
                    style="
                    background-color: white;
                    width: 50px;
                    height: 50px;
                    padding-top: 6px;
                  "
                >
                  <i
                      class="bi bi-facebook"
                      style="font-size: 1.7rem; color: #2b8c83"
                  ></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="col">
            <ul>
              <li>
                <router-link to="/newAccount">New Account</router-link>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li>
                <router-link to="/careers">Careers</router-link>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li>
                <!-- <router-link to="/contactUs">Contact US</router-link> -->
                <a href="" @click.prevent="contactUs">Contact Us</a>
              </li>
            </ul>
          </div>
          <div class="col">
            <p class="mb-0">
              7901 Industrial Village Road Greensboro, NC 27409
            </p>
            <a href="mailto:info@ampfframes.com">info@ampfframes.com</a>
            <p class="mb-0">Tel: 800-640-2467</p>
            <p>Fax: 800-474-1869</p>
          </div>
        </div>
      </div>

      <div class="container" style="padding-top: 20px">
        <p class="text-center">
          &copy; 2024, AMPF INC.
          <router-link to="/privacyPolicy">Privacy Policy</router-link>
          -
          <router-link to="/Terms">Terms</router-link>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    VueMultiselect
  },
  data() {
    return {
      dev: false,
      isLoading: false,
      selectedSearch: "",
      options: [],
      searchTimer: "",
      clearWatch: false,
      adtlSFType: '',
      adtlShow: false,
      adtlStopBlur: false,
      noPurchase: false
    };
  },
  computed: {
    ...mapGetters({
      searchTopData: "product/getSearchTopData",
      isLoggedin: "userAccess/isLoggedin",
      totalCartItems: "cart/getTotalCartItems",
      availableFilters: "product/getFilterData",
      userData: 'userAccess/user'
    }),
  },
  watch: {
    // whenever question changes, this function will run
    selectedSearch: {
      handler(newVal) {
        if (!this.clearWatch) {
          if (newVal.Code != null && newVal.Code != '') {
            this.isLoading = false
            window.location.href = '/Shop/' + newVal.Code;
            //window.location.reload()
          }
          this.clearWatch = true
          this.selectedSearch = ''
        } else {
          this.clearWatch = false
        }
      },
      deep: true,
    },
  },
  methods: {
    isDev() {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
        return true
      }
      return false
    },
    adtlShowBox() {
      this.adtlShow = true
      //multiselect__option

      const optionElements = document.querySelectorAll('.multiselect__option');
      // Loop through each element
      optionElements.forEach(optionElement => {
        // Check if the element exists and contains the text "No products found"
        if (optionElement && optionElement.textContent.trim() === 'List is empty.') {
          // Hide the element by setting its display style to 'none'
          optionElement.style.display = 'none';
        }
      });

    },
    adtlHideBox() {
      if (!this.adtlStopBlur) {
        this.adtlShow = false
      }
    },
    clearSF() {
      this.adtlSFType = ''
    },
    preventEnter(event) {
      if (this.isLoading) {
        event.preventDefault(); // Prevent Enter if loading
      }
    },
    contactUs() {
      window.location.href = '/contactUs'
    },
    disableEnterKeySubmit(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    },
    onEnter() {
      // Handle action when Enter key is pressed
      console.log('Enter key pressed');
      //this.submitForm();
      // You can perform additional actions here if needed
      //this.$nextTick(() => {
      //console.log('next tick')
      //const multiselectInput = this.$el.querySelector('#sbname');
      //multiselectInput.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }));

      //});
    },
    asyncSearch(query) {
      if (query.length > 0) {
        this.adtlShow = true;
      } else {
        this.adtlShow = false;
      }

      this.isLoading = true
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      let self = this
      this.searchTimer = setTimeout(function () {
        //console.log('load data')
        //self.isLoading = false
        self.searchNow(query)
      }, 600);

    },
    searchNow(query) {

      if (query == '') {
        this.isLoading = false
        return
      }
      //this.filters.page = 1
      this.page = 1;
      this.$store
          .dispatch("product/searchTopData", {
            options: {
              TopSearch: query,
              page: 1,
              items_per_page: 10,
              type: this.adtlSFType ? this.adtlSFType : [],
            }
          })
          .then(rez => {
            this.options = rez
            this.isLoading = false
          });

    },
    goTo(rl) {
      this.$router.push(rl);
    },
    logout() {
      this.$store.dispatch("userAccess/logout").then(() => {
        this.$forceUpdate();
        window.location.reload();
      });
    },
  },
  mounted() {
    console.log('env', process.env.NODE_ENV)
    this.dev = this.isDev()
    if (this.isLoggedin) {
      this.$store.dispatch("cart/getCartData", {});
    }

    this.$store.dispatch("product/getFilters", {options: {}}).then(() => {

    })
    let self = this

    document.querySelectorAll('.multiselect__select').forEach(function(element) {
      element.addEventListener('mousedown', function(event) {
        // Stop any existing listeners from preventing the default behavior
        event.stopImmediatePropagation();
        // Your custom functionality here
        console.log('Mousedown re-enabled on multiselect__select');

        //const isAdtlInnerBox = event.target.closest('.adtlInnerBox');
        //const isSbnameInput = event.target.closest('.multiselect__placeholder');
        console.log('cccc',event.target)
        const isMSInput = event.target.closest('.multiselect__select');

        if(isMSInput) {
          console.log('is msinput')
          //self.adtlStopBlur = true
          self.adtlShowBox()
        }

      });
    });

    document.addEventListener('mousedown', function (event) {
      const isAdtlInnerBox = event.target.closest('.adtlInnerBox');
      const isSbnameInput = event.target.closest('.multiselect__placeholder');
      const isSbnameInput2 = event.target.closest('.multiselect__input');
      //multiselect__input
      if (!isAdtlInnerBox) {
        if (self.adtlStopBlur) {
          self.adtlStopBlur = false
        }
        if (!isSbnameInput && !isSbnameInput2) {
          self.adtlHideBox()
        }
      } else {
        self.adtlStopBlur = true
      }
    });

    const input = document.querySelector('#sbname');
    if (input) {
      input.addEventListener('focus', this.adtlShowBox);
      input.addEventListener('blur', this.adtlHideBox);
    }

    let activeAccount = this.userData?.accounts?.[0]?.ActiveType
    let terms = this.userData?.accounts?.[0]?.terms
    if(activeAccount === 'N' || String(terms).toLowerCase() === 'do not sell' || String(terms).toLowerCase() === 'credit hold'){
      this.noPurchase = true
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,400;0,600;0,700;1,300&display=swap");
@import url("vue-multiselect/dist/vue-multiselect.css");

.adtlSF {
  border: 1px solid #cccccc;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 12px;
  background-color: #ffffff;

}

.adtlSFLbl {
  position: relative;
  padding-left: 5px;
  margin-left: 2px;
  margin-right: 2px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  font-size: 12px;
  background-color: #cfe2ff;
  margin-bottom: 15px;
}

.adtlBox {
  position: relative;
  width: 250px;

}

.adtlInnerBox {
  position: absolute;
  width: 220px;
  /*left:15px;*/
  z-index: 10;
  left: -218px;
  top: 0;

}

.devClassTxt {
  position: relative;
  top: -5px;
}

.devClass {
  position: absolute;
  text-align: center;
  font-weight: bold;
  top: 0;
  z-index: 10000;
  width: 100%;
  background-color: red;
  height: 15px;
  font-size: 15px;
  color: white;
}

.devClassLong {
  position: absolute;
  top: 0;
  z-index: 10000;
  width: 10px;
  background-color: red;
  height: 100%;
  font-size: 15px;
  color: white;
}
</style>

<template>
  <div>
    order view
      {{orderData}}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "orderView",
  props:['orderid'],
  computed: {
    ...mapGetters({
      'orderData': 'orders/getOrderDetails',
    }),
  },
  mounted() {
    if(this.orderid != null){
      this.$store.dispatch('orders/orderDetails', this.orderid).then(() => {
        console.log('loaded details');
      })
    }
  }
}
</script>

<style scoped>

</style>
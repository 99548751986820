<template>
  <div>
    <table class="table">
      <thead>
      <th>Details</th>
      </thead>
      <tbody>
      <tr>
        <td>Finish</td>
        <td>{{ finish }}</td>
      </tr>
      <tr>
        <td>Width</td>
        <td>{{ width }}</td>
      </tr>
      <tr>
        <td>Rabbet</td>
        <td>{{ rabbet }}</td>
      </tr>
      <tr>
        <td>Styles</td>
        <td>{{ styles }}</td>
      </tr>
      <tr>
        <td>Length Price</td>
        <td>${{ lengthPriceF }}</td>
      </tr>
      <tr>
        <td>Chop Price</td>
        <td>${{ chopPriceF }}</td>
      </tr>
      <tr>
        <td>Join Price</td>
        <td>${{ joinPriceF }}</td>
      </tr>
      <tr>
        <td>Straight Cut Price..</td>
        <td>${{ exactPriceF }}</td>
      </tr>
      <tr>
        <td>Box Quantity</td>
        <td>{{ boxqty }}</td>
      </tr>
      </tbody>
    </table>

    <label for="type">Type:</label>
    <select name="type" id="type" v-model="productSelection.type" @change="typeChange();">
      <option value="length">Length</option>
      <option value="chop">Chop</option>
      <option value="join">Join</option>
      <option value="exact">Straight Cut</option>
    </select>

    <table>
      <thead>
      <tr>
        <th>Locaton</th>
        <th>Qty</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in productLocationQty.LocationQty" :key="item">
        <td>{{ item.Loc + ' ' + item.Name }}</td>
        <td>{{ locationQty[item.Loc + 'Onhand'] }}</td>
        <td><input type="radio" name="Plocation" v-model="productSelection.location" :value="item.Loc"
                   @change="lookupStock"></td>
      </tr>
      </tbody>
    </table>

    <div id="length_table" v-show="productSelection.type == '' || productSelection.type == 'length'">
      <table>
        <tr>
          <td>
            <label for="length">Length:</label>
          </td>
          <td>
            <input class="dimension" type="number" id="length" name="length" v-model.number="productSelection.length"
                   @blur="lookupStock"/> feet
          </td>
        </tr>
      </table>
    </div>
    <div id="other_table" v-show="productSelection.type != '' && productSelection.type != 'length'">
      <table>
        <tr>
          <td>
            <label for="quantity">Quantity:</label>
          </td>
          <td>
            <input class="quantity" type="number" id="quantity" name="quantity"
                   v-model.number="productSelection.quantity"
                   @keyup="calcType"/>
          </td>
        </tr>

        <tr>
          <td>
            <label>Dimension:</label>
          </td>
          <td>

            <input class="dimension" type="number" id="dimension[length]"
                   name="dimension[length]"
                   v-model="productSelection.dimension.length"
                   @keyup="calcType"/>
            <select id="dimension[length_fraction]" name="dimension[length_fraction]"
                    v-model="productSelection.dimension.length_fraction"
                    @change="calcType">
              <option value=""></option>
              <option value="1/16">1/16</option>
              <option value="1/8">1/8</option>
              <option value="3/16">3/16</option>
              <option value="1/4">1/4</option>
              <option value="5/16">5/16</option>
              <option value="3/8">3/8</option>
              <option value="7/16">7/16</option>
              <option value="1/2">1/2</option>
              <option value="9/16">9/16</option>
              <option value="5/8">5/8</option>
              <option value="11/16">11/16</option>
              <option value="3/4">3/4</option>
              <option value="13/16">13/16</option>
              <option value="7/8">7/8</option>
              <option value="15/16">15/16</option>
            </select> x
            <input class="dimension" type="number" id="dimension[width]"
                   name="dimension[width]"
                   v-model="productSelection.dimension.width"
                   @keyup="calcType"/>
            <select id="dimension[width_fraction]" name="dimension[width_fraction]"
                    v-model="productSelection.dimension.width_fraction"
                    @change="calcType">
              <option value=""></option>
              <option value="1/16">1/16</option>
              <option value="1/8">1/8</option>
              <option value="3/16">3/16</option>
              <option value="1/4">1/4</option>
              <option value="5/16">5/16</option>
              <option value="3/8">3/8</option>
              <option value="7/16">7/16</option>
              <option value="1/2">1/2</option>
              <option value="9/16">9/16</option>
              <option value="5/8">5/8</option>
              <option value="11/16">11/16</option>
              <option value="3/4">3/4</option>
              <option value="13/16">13/16</option>
              <option value="7/8">7/8</option>
              <option value="15/16">15/16</option>
            </select>
          </td>
        </tr>

      </table>
    </div>
    <div id="cartMsg"></div>
    <div id="isInStock" style="text-align:center;" v-show="isInStock == 1">
      <span style="color:green; text-align:center;">In Stock</span>
    </div>
    <div>
      <label for="instructions">Instructions:</label><br/>
      <textarea id="instructions" name="instructions" maxlength="70" v-model="productSelection.instructions" @keyup="instructionsCount"></textarea>
      <span id="maxins">{{maxins}} of 70</span>
      <table>
      </table>

    </div>

    <div id="orderfail"></div>
    <div id="orderconfirm">
      <div id="backorderoptions" v-show="isInStock == 2">
        <table>
          <tr>
            <td colspan="2">
              <span style="font-weight:bold;">Ship:</span> <span id="ShipVal">{{ ShipVal }}</span>
              <span style="font-weight:bold;">Backordered:</span><span id="BackOrderVal">{{ BackOrderVal }}</span>
              <span style="font-weight:bold;">Cancelled:</span> <span id="CancelledVal">{{ CancelledVal }}</span>
            </td>
          </tr>
          <tr>
            <td><input id="firstradio" type="radio" name="backtype" value="backremq"
                       @change="backorderQtyUpdate('backremq')"/></td>
            <td>Backorder remaining quantities</td>
          </tr>
          <tr>
            <td><input type="radio" name="backtype" value="backcancel"
                       @change="backorderQtyUpdate('backcancel')"/></td>
            <td>Cancel remaining quantities</td>
          </tr>
          <tr>
            <td><input type="radio" name="backtype" value="backallq"
                       @change="backorderQtyUpdate('backallq')"/></td>
            <td>Backorder all ordered quantities</td>
          </tr>
        </table>
      </div>

      <div class="button">
        <button id="add_to_cart" type="submit" class="hide" @click="addToCart">Add to Cart
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Fraction from "fraction.js";

export default {
  name: "productDetails",
  props: ['Code'],
  data() {
    return {
      finish: '',
      width: '',
      rabbet: '',
      styles: '',
      hasDiscount: false,
      discountObj: {},
      orglengthPriceF: 0,
      hInt: 0,
      wInt: 0,
      lengthPriceF: 0,
      chopPriceF: 0,
      orgchopPriceF: 0,
      orgjoinPriceF: 0,
      joinPriceF: 0,
      exactPriceF: 0,
      orgexactPriceF: 0,
      orghardwarePriceF: 0,
      hardwarePriceF: 0,
      orgglassPriceF: 0,
      glassPriceF: 0,
      matPriceF: 0,
      orgmatPriceF: 0,
      orgreadyPriceF: 0,
      readyPriceF: 0,
      boxqty: 0,
      joinfee: 0,
      joinosfee: 0,
      yourLength: '',
      yourLengthV: 0,
      customLength: '',
      productWidth: 0,
      isInStock: 0,
      backremq: 0,
      backcancel: 0,
      backallq: 0,
      backtype: '',
      ShipVal: 0,
      onhand: 0,
      BackOrderVal: '',
      CancelledVal: '',
      ShipOrder: 0,
      maxins: 0,
      productType: 0,
      locationQty: {
        GName: '',
        GOnhand: 0,
        BName: '',
        BOnhand: 0,
        CName: '',
        COnhand: 0,
        EName: '',
        EOnhand: 0,
        acctLoc1: '',
        acctLoc1Name: '',
        defaultonhand1Raw: 0,
        defaultonhand1: 0
      },
      productSelection: {
        type: 'length',
        instructions: '',
        length: 0,
        quantity: 0,
        location: '',
        dimension: {
          length: 0,
          length_fraction: 0,
          width: 0,
          width_fraction: 0,
          unitedInches: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      'productDetail': 'product/getProductDetail',
      'productLocationQty': 'product/getProductLocationQty'
    }),
  },
  methods: {
    instructionsCount() {
      this.maxins = this.productSelection.instructions.length
    },
    backorderQtyUpdate(bktype) {
      this.backtype = bktype
      let onhand = this.onhand
      let backremq = this.backremq
      let backcancel = this.backcancel
      let backallq = this.backallq

      this.BackOrderVal = '0'
      this.CancelledVal = '0'

      switch (bktype) {
        case 'backremq':
          this.BackOrderVal = backremq.toFixed(2)
          this.ShipVal = onhand.toFixed(2)
          //$('#backremq').val(backremq.toFixed(2));
          this.ShipOrder = onhand.toFixed(2)
          break;
        case 'backcancel':
          //$('#backcancel').val(backcancel.toFixed(2));
          this.CancelledVal = backcancel.toFixed(2)
          this.ShipVal = onhand.toFixed(2)
          this.ShipOrder = onhand.toFixed(2)
          break;
        case 'backallq':
          //$('#backallq').val(backallq.toFixed(2));
          this.BackOrderVal = backallq.toFixed(2)
          this.ShipVal = 0
          this.ShipOrder = 0
          break;
        default:
          this.BackOrderVal = backremq.toFixed(2)
          //$('#backremq').val(backremq.toFixed(2));
          this.ShipOrder = onhand.toFixed(2)
      }
    },
    lookupStock() {
      this.isInStock = 0
      if (this.productSelection.location && this.productLocationQty.LocationQty[this.productSelection.location] !== undefined) {
        let lenamount = parseFloat(this.productSelection.length)
        this.onhand = parseFloat(this.productLocationQty.LocationQty[this.productSelection.location].Onhand)
        if (lenamount > this.onhand) {
          //not in stock
          this.isInStock = 2
          this.backremq = (lenamount - this.onhand);
          this.backcancel = this.backremq;
          this.backallq = lenamount;
          this.ShipVal = this.onhand.toFixed(2)
          this.backorderQtyUpdate()
        } else {
          //in stock
          this.isInStock = 1
        }
      }
    },
    typeChange() {
      this.clearData()
    },
    calcUnitedInch() {
      this.productSelection.dimension.unitedInches = (parseInt(this.productSelection.dimension.width) + parseInt(this.productSelection.dimension.length))
    },
    addToCart() {
      let pass = true
      if(this.productType === 1 || this.productType === 2 || this.productType === 3 || this.productType === 5){
        if(this.productSelection.quantity <= 0){
          pass = false
        }
      }

      if(pass) {
        document.getElementById("cartMsg").innerHTML = '';
        this.$store.dispatch('cart/addToCart', {Code: this.$props.Code, cartdata: this.$data}).then(() => {})
      }else{
        document.getElementById("cartMsg").innerHTML = "<span style='color:red;'>Quantity must be greater then 0</span>";
      }
    },
    clearData() {
      this.productSelection.length = 0
      this.productSelection.quantity = 0
      this.productSelection.dimension.length = 0
      this.productSelection.dimension.length_fraction = 0
      this.productSelection.dimension.width = 0
      this.productSelection.dimension.width_fraction = 0
      //todo hide back order options

    },
    roundHalf(value) {
      let number = .5;
      //var ceil = Math.ceil(value);
      let remainder = value % number;
      if (remainder > 0)
        value = value - remainder + number;
      return value;
    },
    calcType() {

      let pData = this.productDetail.products[this.Code]

      if (this.productDetail.discounts !== undefined && this.productDetail.discounts[this.Code] !== undefined) {
        this.joinfee = this.productDetail.discounts[this.Code].JoinFee;
        this.joinosfee = this.productDetail.discounts[this.Code].JoinOSFee;
      }else{
        this.joinfee = this.productDetail.discounts.JoinFee ?? 0;
        this.joinosfee = this.productDetail.discounts.JoinOSFee ?? 0 ;
      }

      let lType = this.productSelection.type;
      let ptype = pData.type
      let qty = this.productSelection.quantity

      let n = new Object();
      n.value = qty;
      n.noV = true;
      //lookupStock(n);

      if (ptype != 4) {
        if (qty >= 1) {
          this.customLength = '1'
          //$('#add_to_cart').removeAttr("disabled");
          //$('#add_to_cart').removeClass("disabled");
        } else {
          this.customLength = ''
          //$('#add_to_cart').attr("disabled", true);
          //$('#add_to_cart').addClass("disabled");
        }
        return;
      }

      //alert('test');
      if (qty == "") {
        qty = 1;
      }
      let dimLen = this.productSelection.dimension.length;
      let dimLenFrac = this.productSelection.dimension.length_fraction;
      let dimFullLenV = 0

      if (dimLenFrac != "" && dimLen != "") {
        let dimFullLen = dimLen + ' ' + dimLenFrac;
        let l = new Fraction(dimFullLen);
        dimFullLenV = l.valueOf();
      } else {
        dimFullLenV = parseInt(dimLen);
      }


      let dimWidth = this.productSelection.dimension.width
      let dimWidthFrac = this.productSelection.dimension.width_fraction

      let unitedInches = (parseInt(dimWidth) + parseInt(dimLen));
      this.productSelection.dimension.unitedInches = unitedInches
      let dimFullWidthV = 0;

      if (dimWidthFrac != "" && dimWidth != "") {
        let dimFullWidth = dimWidth + ' ' + dimWidthFrac;
        let w = new Fraction(dimFullWidth);
        dimFullWidthV = w.valueOf();
      } else {
        dimFullWidthV = parseInt(dimWidth);
      }

      let productWidth = this.width
      let pw = new Fraction(productWidth);
      let productWidthV = pw.valueOf();
      this.productWidth = productWidthV

      let totalFT1 = 0;
      let totalFT2 = 0;
      let totalFT3 = 0;
      let totalFT = 0;
      let totalFTQTY = 0;
      if (dimFullLenV != "" && !isNaN(dimFullLenV) &&
          dimFullWidthV != "" && !isNaN(dimFullWidthV)
      ) {

        totalFT1 = (dimFullLenV + dimFullWidthV) * 2;
        totalFT2 = (8 * productWidthV);
        totalFT3 = (totalFT1 + totalFT2);
        totalFT = (totalFT3 / 12);
        totalFTQTY = (this.roundHalf(totalFT) * qty);


        switch (lType) {
          case 'chop':
          case 'join':
            if (totalFTQTY < 4) {
              totalFTQTY = 4;
            }
            break;
        }

        this.yourLength = totalFTQTY + ' FT'
        this.yourLengthV = totalFTQTY
        //$('#add_to_cart').removeAttr("disabled");
        //$('#add_to_cart').removeClass("disabled");

      } else {
        //$('#add_to_cart').attr("disabled", true);
        //$('#add_to_cart').addClass("disabled");
        //$('#yourLengthV').val('');
        this.yourLengthV = 0
      }
    }
  },
  mounted() {
    this.$store.dispatch('product/getProductLocationQty', this.Code).then(() => {
      this.locationQty.acctLoc1 = this.productLocationQty.AccountLocs.Location;
      this.locationQty.acctLoc1Name = this.productLocationQty.AccountLocs.Name;
      this.locationQty.defaultonhand1Raw = 0;
      if (this.productLocationQty.LocationQty[this.locationQty.acctLoc1] != undefined) {
        this.locationQty.defaultonhand1Raw = parseFloat(this.productLocationQty.LocationQty[this.locationQty.acctLoc1].Onhand);
      }
      this.locationQty.defaultonhand1 = Math.round(this.locationQty.defaultonhand1Raw);

      if (this.productLocationQty.LocationQty['B'] != undefined) {
        let BlocRaw = this.productLocationQty.LocationQty['B'];
        this.locationQty.BOnhand = Math.round(parseFloat(BlocRaw.Onhand) - parseFloat(BlocRaw.CommitAmt));
        this.locationQty.BName = BlocRaw.Name;
      }
      if (this.productLocationQty.LocationQty['C'] != undefined) {
        let ClocRaw = this.productLocationQty.LocationQty['C'];
        this.locationQty.COnhand = Math.round(parseFloat(ClocRaw.Onhand) - parseFloat(ClocRaw.CommitAmt));
        this.locationQty.CName = ClocRaw.Name
      }
      if (this.productLocationQty.LocationQty['E'] != undefined) {
        let ElocRaw = this.productLocationQty.LocationQty['E'];
        this.locationQty.EOnhand = Math.round(parseFloat(ElocRaw.Onhand) - parseFloat(ElocRaw.CommitAmt));
        this.locationQty.EName = ElocRaw.Name;
      }
      if (this.productLocationQty.LocationQty['G'] != undefined) {
        let GlocRaw = this.productLocationQty.LocationQty['G'];
        this.locationQty.GOnhand = Math.round(parseFloat(GlocRaw.Onhand) - parseFloat(GlocRaw.CommitAmt));
        this.locationQty.GName = GlocRaw.Name;
      }


    })

    this.$store.dispatch('product/getProductDetails', this.Code).then(() => {
      if (this.productDetail == undefined) {
        return false
      }

      let prodData = this.productDetail

      this.boxqty = prodData.box_qty
      this.finish = prodData.finish_name
      this.styles = prodData.style_name
      this.width = prodData.width_display
      this.rabbet = prodData.rabbet
      this.productType = prodData.type

      this.hInt = prodData.H_Int
      this.wInt = prodData.W_Int

      if (prodData.Vendor != undefined &&
          this.productDetail.discounts[prodData.Vendor] != undefined
      ) {
        this.discountObj = this.productDetail.discounts[prodData.Vendor];
        this.hasDiscount = true;
      }

      let len = parseFloat(prodData.Length);
      len = len.toFixed(2);
      this.orglengthPriceF = len

      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass');
      } else if (this.hasDiscount && this.discountObj['L'] != undefined) {
        let lendis = len * (parseFloat(this.discountObj['L'].DiscRate) / 100);
        len = (len - lendis);
        len = len.toFixed(2);
      } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Length'] != undefined &&
          this.productDetail.discounts['Length'] != ""
      ) {
        len = (len - (len * parseFloat(this.productDetail.discounts['Length']) / 100));
        len = len.toFixed(2);
      } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Length'] != undefined &&
          this.productDetail.discounts['Denver_Length'] != ""
      ) {
        len = (len - (len * parseFloat(this.productDetail.discounts['Denver_Length']) / 100));
        len = len.toFixed(2);
      }

      this.lengthPriceF = len

      let chop = parseFloat(prodData.Chop);
      chop = chop.toFixed(2);
      this.orgchopPriceF = chop
      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass')
      } else if (this.hasDiscount && this.discountObj['C'] != undefined) {
        let chopdis = chop * (parseFloat(this.discountObj['C'].DiscRate) / 100);
        chop = (chop - chopdis);
        chop = chop.toFixed(2);
      } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Chop'] != undefined &&
          this.productDetail.discounts['Chop'] != ""
      ) {
        chop = (chop - (chop * parseFloat(this.productDetail.discounts['Chop']) / 100));
        chop = chop.toFixed(2);
      } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Chop'] != undefined &&
          this.productDetail.discounts['Denver_Chop'] != ""
      ) {
        chop = (chop - (chop * parseFloat(this.productDetail.discounts['Denver_Chop']) / 100));
        chop = chop.toFixed(2);
      }

      this.chopPriceF = chop

      let join = parseFloat(prodData.JoinAmt);
      join = join.toFixed(2);

      this.orgjoinPriceF = join
      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass');
      } else if (this.hasDiscount && this.discountObj['J'] != undefined) {
        let joindis = join * (parseFloat(this.discountObj['J'].DiscRate) / 100);
        join = (join - joindis);
        join = join.toFixed(2);
      } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Join'] != undefined &&
          this.productDetail.discounts['Join'] != ""
      ) {
        join = (join - (join * parseFloat(this.productDetail.discounts['Join']) / 100));
        join = join.toFixed(2);
      } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Join'] != undefined &&
          this.productDetail.discounts['Denver_Join'] != ""
      ) {
        join = (join - (join * parseFloat(this.productDetail.discounts['Denver_Join']) / 100));
        join = join.toFixed(2);
      }

      this.joinPriceF = join

      let exact = parseFloat(prodData.Length2);
      exact = exact.toFixed(2);
      if (this.hasDiscount && this.discountObj['E'] != undefined) {
        let exactdis = exact * (parseFloat(this.discountObj['E'].DiscRate) / 100);
        exact = (exact - exactdis);
        exact = exact.toFixed(2);
      }

      this.exactPriceF = exact
      this.orgexactPriceF = exact


      let hware = parseFloat(prodData.Hardware);

      hware = hware.toFixed(2);
      this.orghardwarePriceF = hware
      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass');
      } else if (this.hasDiscount && this.discountObj['H'] != undefined) {
        let hwaredis = hware * (parseFloat(this.discountObj['H'].DiscRate) / 100);
        hware = (hware - hwaredis);
        hware = hware.toFixed(2);
      } else if (this.productDetail.discounts['Hardware'] != undefined &&
          this.productDetail.discounts['Hardware'] != ""
      ) {
        hware = (hware - (hware * parseFloat(this.productDetail.discounts['Hardware']) / 100));
        hware = hware.toFixed(2);
      }
      this.hardwarePriceF = hware

      let glass = 0

      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass');
        glass = parseFloat(prodData['Glass1']);
      } else if (this.productDetail.discounts['Glass'] == undefined || this.productDetail.discounts['Glass'] == " ") {
        glass = parseFloat(prodData['Glass1']);
      } else if (this.productDetail.discounts['Glass'] == "4") {
        glass = parseFloat(prodData['Mat5']);
      } else {
        glass = parseFloat(prodData['Glass' + this.productDetail.discounts['Glass']]);
      }
      glass = glass.toFixed(2);

      this.orgglassPriceF = glass

      if (this.hasDiscount && this.discountObj['G'] != undefined) {
        let glassdis = glass * (parseFloat(this.discountObj['G'].DiscRate) / 100);
        glass = (glass - glassdis);
        glass = glass.toFixed(2);
      }

      this.glassPriceF = glass

      let mat = 0

      if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
        //console.log('bypass');
        mat = parseFloat(prodData['Mat1']);
      } else if (this.productDetail.discounts['FoamBoard'] == undefined || this.productDetail.discounts['FoamBoard'] == " ") {
        mat = parseFloat(prodData['Mat1']);
      } else {
        mat = parseFloat(prodData['Mat' + this.productDetail.discounts['FoamBoard']]);
      }
      mat = mat.toFixed(2);

      this.orgmatPriceF = mat
      if (this.hasDiscount && this.discountObj['M'] != undefined) {
        let matdis = mat * (parseFloat(this.discountObj['M'].DiscRate) / 100);
        mat = (mat - matdis);
        mat = mat.toFixed(2);
      }

      this.matPriceF = mat

      let ready = parseFloat(prodData.Readymade);
      ready = ready.toFixed(2);
      this.orgreadyPriceF = ready
      this.readyPriceF = ready

      this.calcType()
    })

  }
}
</script>

<style scoped>

</style>
<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto mb-2 mt-2">
        <div class="section-title text-center ">
          <h3 class="top-c-sep">Career's with us</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="career-search mb-60">
          <div class="filter-result">
            <div class="job-box d-md-flex align-items-center justify-content-between mb-30"
                 v-for="career in careerData" :key="career.id"
            >
              <div class="job-left my-4 d-md-flex align-items-center flex-wrap">
                <div class="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
                  {{getInitials(career.job_heading)}}
                </div>
                <div class="job-content ms-3">
                  <h5 class="text-center text-md-left">{{ career.job_heading }}</h5>
                  <ul class="d-md-flex flex-wrap text-capitalize ff-open-sans">
                    <li class="mr-md-4">
                      <i class="zmdi zmdi-pin mr-2"></i> {{ career.job_subject }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="job-right my-4 flex-shrink-0">
                <a href="#" class="btn d-block w-100 d-sm-inline-block btn-secondary"
                   data-bs-toggle="modal"
                   data-bs-target="#careerMethodModal"
                   @click="viewCareer(career)">
                  More Information</a>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div
        class="modal fade"
        id="careerMethodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="careerMethodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="careerMethodModalLabel">
              {{ selectedCareer.job_heading + ' - ' + selectedCareer.job_subject }}</h5>
            <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="card">
                <div class="card-header">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12" v-html="selectedCareer.job_description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
//import $ from 'jquery'
export default {
  name: "careersView",
  data() {
    return {
      selectedCareer: {
        job_heading: '',
        job_subject: '',
        job_description: '',
      }
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      careerData: "Accounts/getCareerData",
    }),
  },
  methods: {
    viewCareer(data) {
      this.selectedCareer = data
      //$("#careerMethodModal").modal("show");
    },
    getInitials(inputString) {
      // Split the input string into words
      const words = inputString.trim().split(' ');

      // If there's only one word, return its uppercase version
      if (words.length === 1) {
        return words[0].charAt(0).toUpperCase();
      }

      // If there are two or more words, extract the first letter from the first two words
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();

      // Concatenate and return the initials
      return firstInitial + secondInitial;
    }
  },
  mounted() {
    this.$store.dispatch('Accounts/careers', {data: {}, method: 'get'})
  }
}
</script>

<style scoped>

.card:hover {
  overflow: hidden;
  transform: none;
}
/* ===== Career ===== */
.career-form {
  background-color: #FAE565;
  border-radius: 5px;
  padding: 0 16px;
}

.career-form .form-control {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  padding: 12px 15px;
  color: #fff;
}

.career-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.career-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.career-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.career-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.career-form .custom-select {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  padding: 12px 15px;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  text-align: left;
  height: auto;
  background-image: none;
}

.career-form .custom-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.career-form .select-container {
  position: relative;
}

.career-form .select-container:before {
  position: absolute;
  right: 15px;
  top: calc(50% - 14px);
  font-size: 18px;
  color: #ffffff;
  content: '\F2F9';
  font-family: "Material-Design-Iconic-Font";
}

.filter-result .job-box {
  background: #fff;
  -webkit-box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  border-radius: 10px;
  padding: 10px 35px;
}

ul {
  list-style: none;
}

.list-disk li {
  list-style: none;
  margin-bottom: 12px;
}

.list-disk li:last-child {
  margin-bottom: 0;
}

.job-box .img-holder {
  height: 65px;
  width: 65px;
  background-color: #FAE565;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(250, 229, 101, 0.9)), to(#c5ab0b));
  background-image: linear-gradient(to right, rgba(250, 229, 101, 0.9) 0%, #c5ab0b 100%);
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 65px;
}

.career-title {
  background-color: #FAE565;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(250, 229, 101, 0.9)), to(#c5ab0b));
  background-image: linear-gradient(to right, rgba(250, 229, 101, 0.9) 0%, #c5ab0b 100%);
}

.job-overview {
  -webkit-box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  box-shadow: 0 0 35px 0 rgba(130, 130, 130, 0.2);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .job-overview {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
  }
}

.job-overview .job-detail ul {
  margin-bottom: 28px;
}

.job-overview .job-detail ul li {
  opacity: 0.75;
  font-weight: 600;
  margin-bottom: 15px;
}

.job-overview .job-detail ul li i {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.job-overview .overview-bottom,
.job-overview .overview-top {
  padding: 35px;
}

.job-content ul li {
  font-weight: 600;
  opacity: 0.75;
  border-bottom: 1px solid #ccc;
  padding: 10px 5px;
}

@media (min-width: 768px) {
  .job-content ul li {
    border-bottom: 0;
    padding: 0;
  }
}

.job-content ul li i {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.mb-30 {
  margin-bottom: 30px;
}
</style>
<script>
export default {
  name: "widthSelectBox",
  data() {
    return {
      form: {
        inputFromValue: '',
        selectedFromValue: '',
        inputToValue: '',
        selectedToValue: ''
      }
    };
  },
  emits:{
    selected:null
  },
  methods: {
    handleInput() {
      // Remove any non-integer characters from the input value
      this.form.inputFromValue = this.form.inputFromValue.replace(/\D/g, '');
      this.form.inputToValue = this.form.inputToValue.replace(/\D/g, '');
    },
    handleChange() {
      this.$emit('selected', this.form);
    },
  },
}
</script>

<template>
  <div class="input-group">
    <div class="flex-container">
      <input type="text" class="form-control custom-input" v-model="form.inputFromValue" @keyup="handleChange"
             @input="handleInput">

      <select class="form-select custom-select" v-model="form.selectedFromValue" @change="handleChange">
        <option></option>
        <option value="0.0625">1/16</option>
        <option value="0.125">1/8</option>
        <option value="0.1875">3/16</option>
        <option value="0.25">1/4</option>
        <option value="0.3125">5/16</option>
        <option value="0.375">3/8</option>
        <option value="0.4375">7/16</option>
        <option value="0.5">1/2</option>
        <option value="0.5625">9/16</option>
        <option value="0.625">5/8</option>
        <option value="0.6875">11/16</option>
        <option value="0.75">3/4</option>
        <option value="0.8125">13/16</option>
        <option value="0.875">7/8</option>
        <option value="0.9375">15/16</option>
      </select>
    </div>
  </div>
  <span class="ToBlock">To</span>
  <div class="flex-container">
    <input type="text" class="form-control custom-input" v-model="form.inputToValue" @keyup="handleChange"
           @input="handleInput">
    <select class="form-select custom-select" v-model="form.selectedToValue" @change="handleChange">
      <option></option>
      <option value="0.0625">1/16</option>
      <option value="0.125">1/8</option>
      <option value="0.1875">3/16</option>
      <option value="0.25">1/4</option>
      <option value="0.3125">5/16</option>
      <option value="0.375">3/8</option>
      <option value="0.4375">7/16</option>
      <option value="0.5">1/2</option>
      <option value="0.5625">9/16</option>
      <option value="0.625">5/8</option>
      <option value="0.6875">11/16</option>
      <option value="0.75">3/4</option>
      <option value="0.8125">13/16</option>
      <option value="0.875">7/8</option>
      <option value="0.9375">15/16</option>
    </select>
  </div>
</template>

<style scoped>
.input-group {
  display: flex;
  align-items: center;
}

.flex-container {
  display: flex;
  gap: 5px; /* Adjust the gap as desired */
}

.custom-input {
  width: 30%;
}

.custom-select {
  width: 50%;
}

.ToBlock {
  display: block;
  text-align: center;
  margin-right: 30px;
}
</style>
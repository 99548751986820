//import router from 'src/routes/routes'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        builderData: null,
        builtFrame: null,
        builtFrameData: null,
        frameBuilderSessionId: null,
        currentBuild: false,
        builderFilters: {moulding:{},mat:{},fillet:{}},
        savedFrames:[]
    },
    getters: {
        getSavedFrames(state){
            return state.savedFrames
        },
        getBuiltFilters(state){
            return state.builderFilters
        },
        getBuiltFrame(state){
            return state.builtFrame
        },
        hasCurrentBuild(state){
            return localStorage.getItem('currentBuild') === null ? state.currentBuild : JSON.parse(localStorage.getItem('frameBuilderSessionId'))
        },
        getFrameBuilderSessionId(state) {
            return localStorage.getItem('frameBuilderSessionId') === null ? state.currentBuild : JSON.parse(localStorage.getItem('currentBuild'))
        },
    },
    actions: {
        initBuilderId({commit,state, getters}){
            if(!state.frameBuilderSessionId){
                let hasS = getters.getFrameBuilderSessionId
                if(!hasS){
                    //create new id and set
                    let newBuilderId = (new Date().getTime() * Math.random() * 100000)
                    commit('setBuilderId',newBuilderId)
                }
            }
        },
        buildFilters({commit}){
            return new Promise((resolve, reject) => {
                let data = {}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/framebuilder/buildFilters',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setBuilderFilters',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        buildFrame({commit},{options}){
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/framebuilder/buildFrame',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setBuiltFrame',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteSavedFrame({commit},id){
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/api/framebuilder/deleteSavedFrame/'+id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                axios(config)
                    .then(response => {
                        commit('setDeletedFrames',id)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getSavedFrames({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options, perPage:1000 }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/framebuilder/getSavedFrames',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setSavedFrames',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        saveDesignFrame(_,{options}){
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/framebuilder/saveFrameBuilder',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        //commit('setBuiltFrame',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateCurrentBuild({commit},bld){
            commit('setCurrentBuild',bld)
        }
    },
    mutations: {
        setCurrentBuild(state, bld){
            localStorage.setItem('currentBuild', bld)
            state.currentBuild = bld
        },
        setBuilderId(state, id){
            localStorage.setItem('frameBuilderSessionId', id)
            state.frameBuilderSessionId = id
        },
        setBuilderData(state, imgData){
            state.builderData = imgData
        },
        setDeletedFrames(state, frameid){
            const index = state.savedFrames.findIndex(obj => obj.id === frameid);
            if (index !== -1) {
                state.savedFrames.splice(index, 1);
            }
        },
        setSavedFrames(state, frames){
            state.savedFrames = frames
        },
        setBuiltFrame(state, imgData){
            state.builtFrame = imgData.outFile
            state.builtFrameData = imgData
        },
        setBuilderFilters(state, filters){
            state.builderFilters = filters
        },
    }
}
<template>
  <div class="container">
    <div class="card mt-5 mb-5">
      <div class="card-header">
        <h4 class="card-title">Terms</h4>
      </div>
      <div class="card-body p-3">
        <div class="row mb-3">
          <div class="col-12">
            <div v-html="terms()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "termPolicy",
  computed: {
    ...mapGetters({
      settingsData: "product/getSettingsData",
    }),
  },
  methods:{
    terms(){
      for (let i = 0; i < this.settingsData.length; i++) {
        if (this.settingsData[i].name === 'Terms') {
          return this.settingsData[i].data;
        }
      }
      return null;
    }
  },
  mounted() {
    this.$store.dispatch('product/settingsData').then(() => {
      //this.loader.hide()
    })
  }
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>
<template>
  <div class="wrapper">

      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12 bg-white">
                  <nav class="nav flex-column">
                      <router-link class="nav-link text-dark boxNav" to="/Admin/userSettings" exact-active-class="active">My Account</router-link>
                      <router-link class="nav-link text-dark boxNav" to="/Admin/userListOrders" exact-active-class="active">Orders Invoiced</router-link>
                      <router-link class="nav-link text-dark boxNav" to="/Admin/userListOrders/pending" exact-active-class="active">Orders Pending</router-link>
                      <router-link class="nav-link text-dark boxNav" to="/Admin/userListOrders/payments" exact-active-class="active">Payments</router-link>
                      <router-link class="nav-link text-dark boxNav" to="/Admin/Credits" exact-active-class="active">Credits</router-link>
                    <!-- <router-link class="nav-link text-white" to="/Admin/PaymentBatch">Payment Batch</router-link> -->
                  </nav>
              </div>
              <div class="col-lg-10 col-md-10 col-sm-12" style="min-height: 500px;">
                  <router-view/>
              </div>
          </div>
      </div>

    <!--<Preloader></Preloader>-->


  </div>
</template>

<script>


export default {
  name: "AdminView",
  components: {
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped>

.active {
  background-color:#2B8C83;
  color:white !important;
}

.boxNav {box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05)}

</style>
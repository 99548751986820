<template>
  <div>
    <!-- Content Header (Page header) -->
    <section class="content-header" v-show="!noData">
      <div class="container-fluid">
        <div class="row mt-2" v-if="orderDetails.synced != 1">
          <div class="col-1"></div>
          <div class="col-7">
            <div class="card">
              <div class="card-body">
                Thank you for your order. Confirmation will be sent via email and appear in the Pending Orders tab shortly
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <div class="row mb-2" v-if="orderDetails.synced == 1">
          <div class="col-10">
            <h1>Order</h1>
          </div>
          <div class="col-2">
            <button class="btn btn-primary mt-2" @click="printContent()">Print</button>
          </div>
          <div class="col-sm-8">
            <h2 v-if="orderDetails.synced != 1" class="text-center text-danger">Pending</h2>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <section class="content" v-show="!noData && orderDetails.synced == 1" id="printContent">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <!--
            <div class="callout callout-info">
              <h5><i class="fas fa-info"></i> Note:</h5>
              This page has been enhanced for printing. Click the print button at the bottom of the invoice to test.
            </div>
            -->

            <!-- Main content -->
            <div class="invoice p-3 mb-3">
              <!-- info row -->
              <div class="row invoice-info">
                <div class="col-sm-4 invoice-col">
                  From
                  <address v-html="displayInvLocation(orderDetails)"></address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  To
                  <address>
                    <strong>{{ orderDetails?.ShipCompany }}</strong
                    ><br/>
                    {{ orderDetails?.ShipAddress1 }} <br/>
                    {{ orderDetails?.ShipAddress2 }}<br/>
                    {{
                      (orderDetails?.ShipCity === null ? '' : orderDetails?.ShipCity) +
                      " " +
                      (orderDetails?.ShipState === null ? '' : orderDetails?.ShipState) +
                      " " +
                      (orderDetails?.ShipZip === null ? '' : orderDetails?.ShipZip)
                    }}<br/>
                  </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  <b
                  >Invoice #
                    <router-link
                        :to="{
                        path: '/Admin/InvoiceDetails/' + orderDetails.Invnum,
                      }"
                    >{{ orderDetails.Invnum }}
                    </router-link
                    >
                  </b
                  ><br/>
                  <br/>
                  <b>Order #:</b> {{ orderDetails.synced != 1 ? '' : orderDetails.Ordnum }}<br/>
                  <b>Payment Due:</b> {{
                    formatDate(orderDetails.DueDate)
                  }}<br/>
                  <b>Account:</b>
                  {{ orderDetails.Acctnum }}
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <div class="row">
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Terms</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.Terms"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Shipvia</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.Shipvia"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Sls</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.Sls"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Pay By</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.PayBy"
                      disabled="disabled"
                  />
                </div>
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">PO #</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.CustPo"
                      disabled="disabled"
                  />
                </div>
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Operator</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="orderDetails.Operator"
                      disabled="disabled"
                  />
                </div>
              </div>

              <!-- Table row -->
              <div class="row">
                <div class="col-12 table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Qty</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>T</th>
                      <th>Footage</th>
                      <th>UnitPrice</th>
                      <th>Join Fee</th>
                      <th>Total</th>
                      <th>Dimension</th>
                      <th>SizeCode</th>
                      <th>Instructions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--
                  <tr v-for="item in orderDetails.order_items" :key="item.Seqnum">
                    <td>{{item.Qty}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Description}}</td>
                    <td>{{item.Pcode}}</td>
                    <td>{{item.Footage}}</td>
                    <td>{{item.UnitPrice}}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{{item.SizeCode}}</td>
                  </tr>
                  -->

                    <tr
                        v-for="item in orderDetails.order_items"
                        :key="item.Seqnum"
                    >
                      <td>{{ item.Qty ? parseInt(item.Qty) : 0 }}</td>
                      <td>{{ item.Code }}</td>
                      <td>{{ item.Description }}</td>
                      <td>{{ item.Pcode }}</td>
                      <td>
                        {{
                          item.Footage
                              ? parseFloat(item.Footage).toFixed(2)
                              : 0
                        }}
                      </td>
                      <td>{{ "$" + formatCurrency(item.UnitPrice) }}</td>
                      <td>
                        {{
                          item.calcJoin !== undefined &&
                          item.calcJoin !== null
                              ? item.calcJoin
                              : 0.0
                        }}
                      </td>
                      <td>{{ formatCurrency(item.TotPrice) }}</td>
                      <td>{{dimensionView(item) }}
                      </td>
                      <td>{{ item.SizeCode }}</td>
                      <td>{{ item.Description1 ?? '' }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <div class="row">
                <!-- accepted payments column -->
                <div class="col-6">
                  <span class="text-danger" style="font-size:20px;" v-if="orderDetails.Invnum !== null && orderDetails.Invnum !== ''">
                    This order has already been invoiced, please click <router-link
                      :to="{path: '/Admin/InvoiceDetails/' + orderDetails.Invnum,}"
                  >here</router-link> to view the invoice
                  </span>
                </div>
                <!-- /.col -->
                <div class="col-6">
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th style="width: 50%">Item Total:</th>
                        <td>${{ formatCurrency(orderDetails.InvTotal) }}</td>
                      </tr>
                      <tr v-if="orderDetails.DiscRate > 0">
                        <th style="width: 50%">
                          Discount%
                          <input
                              style="width: 60px"
                              type="text"
                              readonly
                              :value="orderDetails.DiscRate"
                          />
                        </th>
                        <td>${{ formatCurrency(orderDetails.Discount) }}</td>
                      </tr>
                      <tr>
                        <th>Tax</th>
                        <td>${{ formatCurrency(orderDetails.Tax) }}</td>
                      </tr>
                      <tr>
                        <th>Shipping:</th>
                        <td>${{ formatCurrency(orderDetails.Shipping) }}</td>
                      </tr>
                      <tr>
                        <th>Shipping Cost:</th>
                        <td>${{ formatCurrency(orderDetails.ShipCost) }}</td>
                      </tr>
                      <tr>
                        <th>Packing:</th>
                        <td>${{ formatCurrency(orderDetails.Packing) }}</td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td>${{ formatCurrency(calcTotal(orderDetails)) }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <!-- this row will not appear when printing -->
              <!--
              <div class="row no-print">
                <div class="col-12">
                  <a href="invoice-print.html" rel="noopener" target="_blank" class="btn btn-default"><i class="fas fa-print"></i> Print</a>
                  <button type="button" class="btn btn-success float-right"><i class="far fa-credit-card"></i> Submit
                    Payment
                  </button>
                  <button type="button" class="btn btn-primary float-right" style="margin-right: 5px;">
                    <i class="fas fa-download"></i> Generate PDF
                  </button>
                </div>
              </div>
              -->
            </div>
            <!-- /.invoice -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderDetails",
  props: ["orderid"],
  data() {
    return {
      loader: "dots",
      timeout: 10000, //ms
      color: "#00ab00",
      bgColor: "#4b4b4b",
      height: 64,
      width: 64,
      fullPage: true,
      canCancel: false,
      search: "",
      noData: true,
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ["csv"]},
    };
  },
  computed: {
    ...mapGetters({
      orderDetails: "orders/getOrderDetails",
    }),
  },
  methods: {
    isValid(value) {
      return value !== null && value !== "" && (!isNaN(value) ? Number(value) > 0 : true);
    },
    sanitizeValue(value) {
      console.log('s = ',value);
      return value === null || value === 0 || value === "0" ? "" : value;
    },
    dimensionView(item){
      let disp = ''

      if (
          (this.isValid(item.H_Int) || this.isValid(item.H_Frac)) &&
          (this.isValid(item.W_Int) || this.isValid(item.W_Frac))
      ) {
        disp +=
            this.sanitizeValue(item.H_Int) + ' ' +
            this.sanitizeValue(item.H_Frac);
        disp += ' x ' +
            this.sanitizeValue(item.W_Int) + ' ' +
            this.sanitizeValue(item.W_Frac);
      } else if (this.isValid(item.H_Int) || this.isValid(item.H_Frac)) {
        disp +=
            this.sanitizeValue(item.H_Int) + ' ' +
            this.sanitizeValue(item.H_Frac) + ' (Rail)';
      } else if (this.isValid(item.W_Int) || this.isValid(item.W_Frac)) {
        disp +=
            this.sanitizeValue(item.W_Int) + ' ' +
            this.sanitizeValue(item.W_Frac) + ' (Rail)';
      }
      return disp
    },
    printContent() {
      let content = document.getElementById("printContent").innerHTML;
      let originalContent = document.body.innerHTML;
      document.body.innerHTML = content;
      document.body.style.fontSize = "12px";
      window.print();
      document.body.style.fontSize = "1rem";
      document.body.innerHTML = originalContent;
      location.reload();
    },
    calcTotal(ordD) {
      let InvTotal = parseFloat(ordD.InvTotal)
      let discount = ordD.Discount === null ? 0 : parseFloat(ordD.Discount)
      let tax = ordD.Tax === null ? 0 : parseFloat(ordD.Tax)
      let shipping = ordD.Shipping === null ? 0 : parseFloat(ordD.Shipping)
      let shippingcost = ordD.ShipCost === null ? 0 : parseFloat(ordD.ShipCost)
      let packing = ordD.Packing === null ? 0 : parseFloat(ordD.Packing)

      return (InvTotal + tax + shipping + shippingcost + packing) - (discount)
    },
    displayInvLocation(invD) {
      let dLoc = "<strong>AMPF INC.</strong><br>";
      if (invD.location !== undefined) {
        let invL = invD.location;
        dLoc += invL.Addr1 + "<br>";
        dLoc += invL.Addr2 ? invL.Addr2 + "<br>" : "";
        dLoc += invL.City + ", " + invL.State + " " + invL.Zip + "<br>";
        dLoc += "Tel: 800-640-2467" + "<br>";
        dLoc += "Fax: 800-474-1869" + "<br>";
        dLoc += "E-mail: info@ampfframes.com";
      }
      return dLoc;
    },
    formatDateT(date) {
      let newDate = "";
      if (date != undefined) {
        let dates = date.split("T");
        let datex = dates[0].split("-");
        newDate =
            parseInt(datex[1]) + "/" + parseInt(datex[2]) + "/" + datex[0];
      }
      return newDate;
    },
    formatDate(date) {
      let newDate = "";
      if (date != undefined) {
        let dates = date.split(" ");
        let datex = dates[0].split("-");
        newDate =
            parseInt(datex[1]) + "/" + parseInt(datex[2]) + "/" + datex[0];
      }
      return newDate;
    },
  },
  mounted() {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: this.canCancel,
      color: this.color,
      backgroundColor: this.bgColor,
      height: this.height,
      width: this.width,
      loader: this.loader,
      opacity: 0.3,
    });
    this.$store
        .dispatch("orders/getAdminOrderDetails", {Ordernum: this.orderid})
        .then((rez) => {
          if (Object.keys(rez).length > 0) {
            this.noData = false;
          }
          loader.hide();
        });
  },
};
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}

@media print {
  body {
    margin: 0;
    padding: 0;
    font-size:8px;
  }
  #printContent {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
  }
}
</style>

<script>
export default {
  name: "matBox",
  props: ['mType','filters','locked'],
  data() {
    return {
      form: {
        inputFromValue: '',
        selectedFromValue: '',
        inputToValue: '',
        selectedToValue: ''
      }
    };
  },
  watch: {
    'filters.matTop': function (newVal) {
      this.inputFromValue = this.filters.matTop
      this.checkLock(newVal);
    },
    'filters.matTopFrac': function (newVal) {
      this.selectedFromValue = this.filters.matTopFrac
      this.checkLockFrac(newVal);
    }
  },
  emits: {
    selected: null
  },
  methods: {
    checkLock(d) {
      if (this.locked) {
        this.form.inputFromValue = d;
        this.form.inputToValue = d;
        //this.form.selectedFromValue = f;
        //this.form.selectedToValue = f;
        this.handleChange()
      }
    },
    checkLockFrac(f) {
      if (this.locked) {
        this.form.selectedFromValue = f;
        this.form.selectedToValue = f;
        this.handleChange()
      }
    },
    handleInput() {
      // Remove any non-integer characters from the input value

      if(this.form.inputFromValue !== "") {
        this.form.inputFromValue = parseInt(this.form.inputFromValue)
        //this.form.inputFromValue = this.form.inputFromValue.replace(/\D/g, '');
      }
      if(this.form.inputToValue !== "") {
        this.form.inputToValue = parseInt(this.form.inputToValue)
        //this.form.inputToValue = this.form.inputToValue.replace(/\D/g, '');
      }
    },
    handleChange() {
      this.$emit('selected', this.form);
    },
  },
  mounted() {
    if(this.mType === "TB"){
      this.form.inputFromValue = this.filters.matTop
      this.form.inputToValue = this.filters.matBottom
      this.form.selectedFromValue = this.filters.matTopFrac
      this.form.selectedToValue = this.filters.matBottomFrac
    }

    if(this.mType === "LR"){
      this.form.inputFromValue = this.filters.matLeft
      this.form.inputToValue = this.filters.matRight
      this.form.selectedFromValue = this.filters.matLeftFrac
      this.form.selectedToValue = this.filters.matRightFrac
    }

    if(this.mType === "opening"){
      this.form.inputFromValue = this.filters.matOpenWidth
      this.form.inputToValue = this.filters.matOpenHeight
      this.form.selectedFromValue = this.filters.matOpenWidthFrac
      this.form.selectedToValue = this.filters.matOpenHeightFrac
    }
  }
}
</script>
"#dimTop, #dimBottom, #dimLeft, #dimRight"
<template>
  <div class="input-group">
    <div class="container">
      <div class="row">
        <div class="col-10 text-center">{{ (mType === 'opening' ? 'Width' : (mType === 'TB' ? 'Top' : (mType === 'LR' ? 'Left' : ''))) }}</div>
      </div>
    </div>
    <div class="flex-container">
      <input type="text" :id="(mType === 'TB' ? 'dimTop': (mType === 'LR' ? 'dimLeft' : ''))" class="form-control custom-input" v-model="form.inputFromValue" @keyup="handleChange"
             @input="handleInput">

      <select :id="(mType === 'TB' ? 'dimTopFrac': (mType === 'LR' ? 'dimLeftFrac' : ''))" class="form-select custom-select" v-model="form.selectedFromValue" @change="handleChange">
        <option></option>
        <option value="0.0625">1/16</option>
        <option value="0.125">1/8</option>
        <option value="0.1875">3/16</option>
        <option value="0.25">1/4</option>
        <option value="0.3125">5/16</option>
        <option value="0.375">3/8</option>
        <option value="0.4375">7/16</option>
        <option value="0.5">1/2</option>
        <option value="0.5625">9/16</option>
        <option value="0.625">5/8</option>
        <option value="0.6875">11/16</option>
        <option value="0.75">3/4</option>
        <option value="0.8125">13/16</option>
        <option value="0.875">7/8</option>
        <option value="0.9375">15/16</option>
      </select>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-10 text-center fw-bold">X</div>
    </div>
    <div class="row">
      <div class="col-10 text-center">{{ (mType === 'opening' ? 'Height' : (mType === 'TB' ? 'Bottom' : (mType === 'LR' ? 'Right' : ''))) }}</div>
    </div>
  </div>
  <div class="flex-container">

    <input type="text" :id="(mType === 'TB' ? 'dimBottom': (mType === 'LR' ? 'dimRight' : ''))" class="form-control custom-input" v-model="form.inputToValue" @keyup="handleChange"
           @input="handleInput">
    <select :id="(mType === 'TB' ? 'dimBottomFrac': (mType === 'LR' ? 'dimRightFrac' : ''))" class="form-select custom-select" v-model="form.selectedToValue" @change="handleChange">
      <option></option>
      <option value="0.0625">1/16</option>
      <option value="0.125">1/8</option>
      <option value="0.1875">3/16</option>
      <option value="0.25">1/4</option>
      <option value="0.3125">5/16</option>
      <option value="0.375">3/8</option>
      <option value="0.4375">7/16</option>
      <option value="0.5">1/2</option>
      <option value="0.5625">9/16</option>
      <option value="0.625">5/8</option>
      <option value="0.6875">11/16</option>
      <option value="0.75">3/4</option>
      <option value="0.8125">13/16</option>
      <option value="0.875">7/8</option>
      <option value="0.9375">15/16</option>
    </select>
  </div>
</template>

<style scoped>
.input-group {
  display: flex;
  align-items: center;
}

.flex-container {
  display: flex;
  gap: 5px; /* Adjust the gap as desired */
}

.custom-input {
  width: 30%;
}

.custom-select {
  width: 50%;
}

.ToBlock {
  display: block;
  text-align: center;
  margin-right: 30px;
}
</style>
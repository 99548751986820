<template>
    <div>
        Payment Batch

        <div class="card" v-if="!filter">
            <div class="card-header">
                <h3 class="card-title">Filters</h3>
                <!-- /.card-tools -->
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label for="batchnum">Batch #</label>
                            <input id="batchnum" type="text" class="form-control" v-model="search.batchId">
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button class="btn btn-primary float-right" @click.prevent="runSearch">Search</button>
            </div>
        </div>

        <table id="accounts1" class="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Payment Id</th>
                <th>Batch Id</th>
                <th>Invoice Id</th>
                <th>Status</th>
                <th>Created</th>
                <th>Updated</th>
            </tr>
            </thead>
            <tbody>

            </tbody>
        </table>

    </div>
</template>

<script>
import $ from 'jquery'
import {mapGetters} from "vuex";

export default {
    name: "PaymentBatch",
    data() {
        return {
            search:{
                batchId:null
            },
            options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
        }
    },

    computed: {
        ...mapGetters({
            'accountData': 'Accounts/getAccounts',
        }),
    },
    methods:{
        convertToEST(utcdate) {
            const options = {
                timeZone: "America/New_York",
                timeZoneName: "short"
            };
            return new Date(utcdate).toLocaleString("en-US", options);
        },
        goTo(rl){
            this.$router.push(rl)
        },
        onint(){
            $("#accounts1_wrapper input[type='search']").off();
            // Use return key to trigger search
            $("#accounts1_wrapper input[type='search']").on("keyup", function(evt){
                this.search = $(this).val();
                if(evt.keyCode == 13){
                    $("#accounts1").DataTable().draw();
                }
            });
        },
        runSearch() {
            //this.search = $('[aria-controls="accounts1"]').val();

            //$("#accounts1").DataTable().search(this.search).draw();
            $("#accounts1").DataTable().search('').draw();
        },
        loadData(){
            let self = this
            $("#accounts1").DataTable({
                ajax: function (data, callback) {
                    let sPage = (parseInt($("#accounts1").DataTable().page()) + 1);
                    let search = $("#accounts1_wrapper input[type='search']").val();
                    let orderCol = data.columns[data.order[0].column].data;
                    let orderDir = data.order[0].dir;
                    //let sOrder = '';
                    self.$store.dispatch('orders/paymentBatchData',{options:{page: sPage, perPage: data.length, search, orderCol, orderDir, ...self.search}}).then(adata => {
                        const tmpJson = {
                            recordsTotal: adata.total, // expected by DataTables to create pagination
                            recordsFiltered: adata.total, // expected by DataTables to create pagination
                            data: adata.data, // expected by DataTables to populate the table
                            currentPage: adata.current_page // added by me to easily manage correct page displaying
                        }

                        callback(
                            tmpJson
                        )
                    });
                },
                "initComplete":function(){self.onint();},
                processing: true,
                serverSide: true,
                "columns" : [
                    { "data" : "id" },
                    { "data" : "payment_batch_id" },
                    {
                        "data": 'invoice_id',
                        "render": function (data, type, row) {
                                return '<a href="/Admin/InvoiceDetails/' + row.invoice_id + '">' + row.invoice_id + '</a>';
                        }
                    },
                    {
                        "data": 'status',
                        "render": function (data, type, row) {
                            return row.payment_status !== null ? row.payment_status.name : 'In-Process';
                        }
                    },
                    {
                        "data": 'created_at',
                        "render": function (data, type, row) {
                            return self.convertToEST(row.created_at);
                        }
                    },
                    {
                        "data": 'updated_at',
                        "render": function (data, type, row) {
                            return self.convertToEST(row.updated_at);
                        }
                    },
                ],
                "responsive": true, "lengthChange": false, "autoWidth": false,
            });

        }
    },
    mounted() {
        this.loadData()
    }
}
</script>

<style scoped>

#orders1 thead th {
  white-space: nowrap;
}

.card:hover {
  overflow: hidden;
  transform: none;
}
</style>





<template>
  <div>
    <table class="table">
      <thead>
      </thead>
      <tbody>
      <tr v-for="item in productData" :key="item">
        <td>{{item.Code}}</td>
        <td>{{item.Description}}</td>
        <td>
          <button @click="addToCart(item.Code)">Add To Cart</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "productSearch",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      'productData': 'product/getProducts',
    }),
  },
  methods: {
    addToCart(Code){
      this.$router.push({ name: 'productDetail', params: {Code}})
      /*
      this.$store.dispatch('cart/addToCart',code).then(() => {
        console.log('loaded cart');
      })
      */
    }
  },
  mounted() {
    this.$store.dispatch('product/getProductData',{}).then(() => {})
  }
}
</script>

<style scoped>

</style>
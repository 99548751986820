import axios from 'axios'

export default {
    namespaced: true,
    state: {
        userData: null,
        locationData:{}
    },
    getters: {
        getLocation(state){
            return state.locationData
        },
        getUserData(state){
            return state.userData
        },

    },
    actions: {
        updateUserInfo(_,data){
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/api/users/self',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('vtoken')
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        userData({commit},{options}){
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/user/getUserDetails',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setUserData',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        setLocations({commit}){
            return new Promise((resolve, reject) => {
                let data = { }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/locations',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setLocationData',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setLocationData(state, locationData){
            state.locationData = !locationData ? {} : locationData
        },
        setUserData(state, userData){
            state.userData = userData
        },

    }
}
//import router from '@/router'
import store from '@/store'
import {useToast} from "vue-toastification";

let installed = false;

const cartCheckPlugin = {
    install(Vue, options) {
        let loggedIn = store.getters['userAccess/isLoggedin']
        if(!loggedIn) return;

        if (installed) return;
        installed = true;
        const timeoutDuration = options.timeoutDuration || 1 * 60 * 60 * 1000; // Default to 1 hour

        const getLastActivityTime = () => {
            return parseInt(localStorage.getItem('lastCartActivityTime'), 10) || 0;
        };

        const setLastActivityTime = () => {
            localStorage.setItem('lastCartActivityTime', Date.now());
        };

        const shouldNotify = () => {
            const lastActivityTime = getLastActivityTime();
            const currentTime = Date.now();
            return currentTime - lastActivityTime > timeoutDuration;
        };

        const notify = () => {
            //check cart

            store.dispatch('cart/getCartData',{}).then(rez => {
                if(rez){
                    const toast = useToast();
                    toast.warning("Cart items empty at midnight. Don't forget to Submit your order.",{
                        timeout: 55 * 60 * 1000,
                    });
                }
            })

            /*
            window.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('keydown', resetIdleTimer);
            window.removeEventListener('click', resetIdleTimer);
                //router.push({name: 'Login'})
            store.dispatch("userAccess/logout").then(() => {
                router.push({name: 'Login'}).then(() => {
                    window.location.reload();
                })
            });
             */
        };

        const resetIdleTimer = () => {
            setLastActivityTime();
        };

        resetIdleTimer(); // Reset the idle timer when the plugin is installed

        // Set up a recurring check for idle timeout using setInterval
        setInterval(() => {
            if (shouldNotify()) {
                notify();
            }
        }, timeoutDuration);

        Vue.mixin({
            created() {
                /*
                window.addEventListener('mousemove', resetIdleTimer);
                window.addEventListener('keydown', resetIdleTimer);
                window.addEventListener('click', resetIdleTimer);
                 */
            },
            beforeUnmount() {
                /*
                window.removeEventListener('mousemove', resetIdleTimer);
                window.removeEventListener('keydown', resetIdleTimer);
                window.removeEventListener('click', resetIdleTimer);
                */
            },
        });
    },
};

export default cartCheckPlugin;

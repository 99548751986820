<template>
  <div class="container">
      <div class="card collapsed-card mt-5 mb-5">
        <div class="card-header">
          <h4 class="card-title">{{msgHeading}}</h4>
        </div>
        <div class="card-body p-3">
          <div class="row mb-3">
            <div class="col-12" v-html="msg"></div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: "AccountMsg",
  data() {
    return {
      'msg': '',
      'msgHeading': ''
    }
  },
  mounted() {
    this.msg = this.$route.params.msg;
    this.msgHeading = this.$route.params.msgHeading;
  }

}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>
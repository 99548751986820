<template>
  <div class="container">
    <h1>Contact Us</h1>
    <form @submit.prevent="submitForm">
      <input type="hidden" name="_csrf" :value="csrfToken">

      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="form.name" required>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email address</label>
        <input type="email" class="form-control" id="email" v-model="form.email" required>
      </div>
      <div class="mb-3">
        <label for="phone" class="form-label">Phone</label>
        <input type="text" class="form-control" id="phone" v-model="form.phoneNumber" v-on:input="formatPhoneNumber">
      </div>
      <div class="mb-3">
        <label for="message" class="form-label">Message</label>
        <textarea class="form-control" id="message" rows="5" v-model="form.message" required></textarea>
      </div>

      <div class="g-recaptcha" data-sitekey="6LddFlcmAAAAADng6qSwolLmIRo-PO6Gsa6DzwRy"></div>


      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script>
import $ from 'jquery'
import {useToast} from "vue-toastification";

export default {
  name: "ContactUs",
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast};
  },
  data() {
    return {
      csrfToken:'',
      form: {
        name:'',
        email:'',
        message:'',
        phoneNumber: '',
        recap:''
      }
    };
  },
  methods: {
    submitForm() {
      if ( $('#g-recaptcha-response').val() === '' ) {
        this.toast.error("Please check reCaptcha");
        return
      }

      this.form.recap = $('#g-recaptcha-response').val()

      this.$store
          .dispatch("Accounts/sendContactUs", this.form)
          .then((rez) => {
            console.log('my rez',rez);
            //loader.hide();
          });

    },
    formatPhoneNumber() {
      // Remove all non-digit characters from the phone number
      let cleanedPhoneNumber = this.form.phoneNumber.replace(/\D/g, '');

      // Limit the phone number to 10 digits
      cleanedPhoneNumber = cleanedPhoneNumber.slice(0, 10);

      // Apply the desired format
      if (cleanedPhoneNumber.length > 6) {
        cleanedPhoneNumber = cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      } else if (cleanedPhoneNumber.length > 3) {
        cleanedPhoneNumber = cleanedPhoneNumber.replace(/(\d{3})(\d{1,3})/, '($1) $2');
      }

      this.form.phoneNumber = cleanedPhoneNumber;
    }
  },
  mounted() {

    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.defer = true;

    // Append the script element to the document's head
    document.head.appendChild(script);

    this.$store.dispatch("Accounts/contactUs").then(res => {
      this.csrfToken = res.csrf
    });

  }
}
</script>

<style scoped>

</style>
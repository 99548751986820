<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-2"></div>
      <div class="col-8" >

        <div class="card">
          <div class="card-header">New Account</div>
          <div class="card-body">
            <p class="card-text">
              Download application and email to
              <a href="mailto:orders@ampfframes.com?subject=New%20Application">orders@ampfframes.com</a>
              <br><br>
              <button class="btn btn-secondary" @click="dlPdf(8)">Download</button>
            </p>
          </div>
        </div>

      </div>
      <div class="col-2"></div>
    </div>
    <iframe id="DLframe" :src="iframeSrc" style="width: 0; height: 0;" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "whoWeAre",
  data() {
    return {
      iframeSrc: '',
      loader: "dots",
      who: ''
    };
  },
  methods:{
    dlPdf(id){
      this.iframeSrc = process.env.VUE_APP_API_SERVER+'/api/pdfDownload?active=1&dlid='+id
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>
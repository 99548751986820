<template>
  <div class="hold-transition login-page mt-3 mb-3">
    <div class="login-box content">
      <div class="row">'
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="card card-outline card-primary">
            <div class="card-body">
              <p class="login-box-msg">You are only one step a way from your new password, recover your password
                now.</p>
              <p class="alert alert-danger" role="alert" v-show="showmsg" v-html="msg"></p>
              <form @submit.prevent="submitForm" autocomplete="off">
                <div class="input-group mb-3">
                  <input type="password" id="password1" v-model="form.password" class="form-control"
                         placeholder="Password">
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <font-awesome-icon class="lockinbox p-1" icon="lock"/>
                      <font-awesome-icon class="toggle-password" :icon="tglpass1" id="togglePassword1"/>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input type="password" id="password2" v-model="form.password_confirmation" class="form-control"
                         placeholder="Confirm Password">
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <font-awesome-icon class="lockinbox p-1" icon="lock"/>
                      <font-awesome-icon class="toggle-password" :icon="tglpass2" id="togglePassword2"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary btn-block">Change password</button>
                  </div>
                  <!-- /.col -->
                </div>
              </form>

              <p class="mt-3 mb-1">
                <a href="login.html">Login</a>
              </p>
            </div>
            <!-- /.login-card-body -->
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {mapGetters} from "vuex";

export default {
  name: "ResetPassword",
  setup: () => ({v$: useVuelidate()}),
  props: ['token', 'email'],
  data() {
    return {
      msg: '',
      tglpass1: 'eye',
      tglpass2: 'eye',
      showmsg: false,
      form: {
        password: null,
        password_confirmation: null
      }
    }
  },
  validations() {
    return {
      form: {
        password: {required},
        password_confirmation: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    async submitForm() {
      let self = this
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/resetPassword', {
        token: this.token,
        email: this.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation
      })
          .then(async res => {
            if (res.data.status !== undefined && res.data.status === 'passwords.reset') {
              //display message and redirect to login
              this.showmsg = true
              this.msg = "Password has been reset please login. You are being redirected"
              setTimeout(function () {
                self.$router.push({name: 'Login'})
              }, 3000)
            } else {
              this.msg = res.data.data.password.toString().replace(',', '<br />')
              this.showmsg = true;
            }
          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
  }, mounted() {
    this.$store.dispatch('userAccess/resetCheck', {token: this.token, email: this.email})
        .then(async res => {
          if (res !== 200) {
            this.$router.push({name: 'Login'})
          }
        })
        .catch(() => {
          this.$router.push({name: 'Login'})
        })

    const passwordInput1 = document.getElementById("password1");
    const togglePasswordButton1 = document.getElementById("togglePassword1");
    let self = this
    togglePasswordButton1.addEventListener("click", function () {
      if (passwordInput1.type === "password") {
        passwordInput1.type = "text";
        self.tglpass1 = 'eye-slash';
      } else {
        passwordInput1.type = "password";
        self.tglpass1 = 'eye';
      }
    });

    const passwordInput2 = document.getElementById("password2");
    const togglePasswordButton2 = document.getElementById("togglePassword2");

    togglePasswordButton2.addEventListener("click", function () {
      if (passwordInput2.type === "password") {
        passwordInput2.type = "text";
        self.tglpass2 = 'eye-slash';
      } else {
        passwordInput2.type = "password";
        self.tglpass2 = 'eye';
      }
    });
  },
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}

.lockinbox {

}


/*password eye*/
.password-container {
  position: relative;
}

#togglePassword1, #togglePassword2 {
  padding-right: 30px; /* Space for the icon */
}

.toggle-password {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
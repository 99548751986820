<template>
  <div>

    <div class="modal fade" id="shopDModal" tabindex="-1" aria-labelledby="shopDModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-5">
                  <div class="prod_img bg-light p-4">
                    <img :src="detailImg"
                         @error="imageUrlAlt"
                         @click="openLB([detailImg], 0)"
                         class="img-fluid img-cursor-pointer">
                  </div>
                  <div class="p-4" v-if="isLoggedin">
                    <span style="text-decoration: underline;">Location (Qty Available)</span><br>
                    <span v-html="getLocationQty()"></span>
                  </div>
                </div>
                <div class="col-lg-7 ms-auto">
                  <button type="button" class="btn-close position-absolute" data-bs-dismiss="modal"
                          aria-label="Close" @click="clearDetails"></button>

                  <div class="row">
                    <div class="col-12 text-center">
                      <h2 class="text-center  mt-4">Item Number: {{ details.Code }}</h2>
                    </div>
                    <div class="col-12 text-center">
                      <span class="fw-semibold text-center">{{ details.display_name }}</span>
                    </div>
                    <div class="col-12 text-center">
                      <span class="text-center">{{ details.Description }}</span>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-6" v-if="isLoggedin">
                      <div class="row">
                        <div class="col-2">
                          <h5 class="fw-semibold">Pricing</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <ul class="prod-details" v-if="getFrameType(details.Code) === 'MOULDING'">
                            <li>Length Price<span class="fw-semibold ms-3">${{
                                isNaN(detailData.lengthPriceF) ? 0 : detailData.lengthPriceF
                              }}</span></li>
                            <li>Chop Price <span class="fw-semibold ms-3">${{ isNaN(detailData.chopPriceF) ? 0 : detailData.chopPriceF }}</span>
                            </li>
                            <li>Join Price <span class="fw-semibold ms-3">${{ isNaN(detailData.joinPriceF) ? 0 : detailData.joinPriceF }}</span>
                            </li>
                            <li>Exact Price <span class="fw-semibold ms-3">${{
                                isNaN(detailData.exactPriceF) ? 0 : detailData.exactPriceF
                              }}</span></li>
                            <li>Box Price <span class="fw-semibold ms-3">${{
                                isNaN(detailData.boxPrice) ? 0 : detailData.boxPrice
                              }}</span></li>
                          </ul>
                          <ul class="prod-details mt-3" v-if="getFrameType(details.Code) === 'HARDWARE'">
                            <li>Price <span class="fw-semibold ms-3">${{
                                isNaN(detailData.hardwarePriceF) ? 0 : detailData.hardwarePriceF
                              }}</span></li>
                          </ul>
                          <ul class="prod-details mt-3" v-if="getFrameType(details.Code) === 'GLASS'">
                            <li>Price <span class="fw-semibold ms-3">${{
                                isNaN(detailData.glassPriceF) ? 0 : detailData.glassPriceF
                              }}</span></li>
                          </ul>
                          <ul class="prod-details mt-3" v-if="getFrameType(details.Code) === 'MATS'">
                            <li>Price <span class="fw-semibold ms-3">${{
                                isNaN(detailData.matPriceF) ? 0 : detailData.matPriceF
                              }}</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-2">
                          <h5 class="fw-semibold">Details</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">Width</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">Finish</p>
                          <p class="mb-1" style="white-space: nowrap;">Box Quantity</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">Rabbet</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">Styles</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">Collection</p>
                        </div>
                        <div class="col-6">
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">
                            {{ details.widthFrac ? details.widthFrac : "&nbsp;" }}</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">
                            {{ details.finish_name ? details.finish_name : "&nbsp;" }}</p>
                          <p class="mb-1">{{ details.box_qty ? details.box_qty : "&nbsp;" }}</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">
                            {{ details.rabbet ? details.rabbet : "&nbsp;" }}</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">
                            {{ details.style_name ? details.style_name : "&nbsp;" }}</p>
                          <p class="mb-1" v-if="details.FrameType === 'MOULDING'">
                            <a href="" @click.prevent="goToCol(details.category)">
                              <span>{{ details.collection_name ? details.collection_name : "&nbsp;" }}</span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "shopDetails",
  props: ['details', 'detailImg', 'detailData'],
  data() {
    return {
      defaultImg: require("@/assets/images/img-card-logo1.png"),
    };
  },
  computed: {
    ...mapGetters({
      'productDetail': 'product/getProductDetail',
      'productLocationQty': 'product/getProductLocationQty',
      'isLoggedin': "userAccess/isLoggedin",
    }),
  },
  watch: {
    // whenever question changes, this function will run
    details: {
      handler() {
        console.log('detail update')
      },
      deep: true,
    },
  },
  methods: {
    goToCol(rl) {
      //this.$router.push(rl);
      location.href = '/shop?collection='+rl
    },
    openLB(img,index){
      this.$emit('openLightbox',img, index)
    },
    clearDetails(){
      this.locationInfo = '';
      //this.$emit('clearDetails');
    },
    getLocationQtyLbl(fType){
      return fType === 'MOULDING' ? 'ft' : 'qty'
    },
    getLocationQty(){
      let LocTxt = '';

      let lq = this.productLocationQty.LocationQty

      for (let key in lq) {
          let innerObj = lq[key];
          if(innerObj.Loc !== 'A' && innerObj.Loc !== 'D'){
            let lQty = parseInt(innerObj.Onhand)
            LocTxt += innerObj.Name+' - '+lQty+' '+this.getLocationQtyLbl(this.details.FrameType)+'<br>'
          }
      }
      return LocTxt
    },
    getFrameType(Code){
      let ftype = ''
      if(Code !== undefined && Code !== '' &&
          this.productDetail.products !== undefined &&
          this.productDetail?.products[Code] !== undefined
      ) {
        ftype = this.productDetail.products[Code].FrameType
      }
      return ftype
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
  }

}
</script>

<style scoped>

</style>
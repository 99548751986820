<template>
  <div>
    store locs
  </div>
</template>

<script>
export default {
  name: "StoreLocations"
}
</script>

<style scoped>

</style>
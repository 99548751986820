import router from '@/router'
import store from '@/store'

let installed = false;

const InactivityLogoutPlugin = {
    install(Vue, options) {
        let loggedIn = store.getters['userAccess/isLoggedin']
        if(!loggedIn) return;

        if (installed) return;
        installed = true;
        const timeoutDuration = options.timeoutDuration || 12 * 60 * 60 * 1000; // Default to 12 hours

        const getLastActivityTime = () => {
            return parseInt(localStorage.getItem('lastActivityTime'), 10) || 0;
        };

        const setLastActivityTime = () => {
            localStorage.setItem('lastActivityTime', Date.now());
        };

        const shouldLogout = () => {
            const lastActivityTime = getLastActivityTime();
            const currentTime = Date.now();
            return currentTime - lastActivityTime > timeoutDuration;
        };

        const logout = () => {
            window.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('keydown', resetIdleTimer);
            window.removeEventListener('click', resetIdleTimer);
                //router.push({name: 'Login'})
            store.dispatch("userAccess/logout").then(() => {
                router.push({name: 'Login'}).then(() => {
                    window.location.reload();
                })
            });
        };

        const resetIdleTimer = () => {
            setLastActivityTime();
        };

        resetIdleTimer(); // Reset the idle timer when the plugin is installed

        // Set up a recurring check for idle timeout using setInterval
        setInterval(() => {
            if (shouldLogout()) {
                logout();
            }
        }, timeoutDuration);

        Vue.mixin({
            created() {
                window.addEventListener('mousemove', resetIdleTimer);
                window.addEventListener('keydown', resetIdleTimer);
                window.addEventListener('click', resetIdleTimer);
            },
            beforeUnmount() {
                window.removeEventListener('mousemove', resetIdleTimer);
                window.removeEventListener('keydown', resetIdleTimer);
                window.removeEventListener('click', resetIdleTimer);
            },
        });
    },
};

export default InactivityLogoutPlugin;

<template>
  <div class="container">

    <div class="row" v-if="stepNumber === 2">
      <div class="col-md-12 p-2">
        <div class="row">
          <div class="col-1">
            <button class="btn btn-secondary mt-2 mb-2 text-center" type="button" @click.prevent="startOver"
            ><strong>Back</strong></button>
          </div>
          <div class="col-3">

          </div>
          <div class="col-2">
            <label for="fwidth" class="form-label">Width (Max 90)</label>
            <input
                type="text"
                class="form-control"
                id="fwidth"
                v-model="fWidthCalc"
                @input="calcWidth"
                placeholder="Width"
            />
          </div>
          <div class="col-2">
            <label for="fwidthd" class="form-label">&nbsp;</label>
            <select id="fwidthd" class="form-select custom-select" v-model="fWidthDCalc" @change="calcWidth">
              <option></option>
              <option value="0.0625">1/16</option>
              <option value="0.125">1/8</option>
              <option value="0.1875">3/16</option>
              <option value="0.25">1/4</option>
              <option value="0.3125">5/16</option>
              <option value="0.375">3/8</option>
              <option value="0.4375">7/16</option>
              <option value="0.5">1/2</option>
              <option value="0.5625">9/16</option>
              <option value="0.625">5/8</option>
              <option value="0.6875">11/16</option>
              <option value="0.75">3/4</option>
              <option value="0.8125">13/16</option>
              <option value="0.875">7/8</option>
              <option value="0.9375">15/16</option>
            </select>

          </div>
          <div class="col-2">
            <label for="fheight" class="form-label">Height (Max 90)</label>
            <input
                type="text"
                class="form-control"
                id="fheight"
                v-model="fHeightCalc"
                @input="calcHeight"
                placeholder="Height"
            />
          </div>
          <div class="col-2">
            <label for="fheightd" class="form-label">&nbsp;</label>
            <select id="fheightd" class="form-select custom-select" v-model="fHeightDCalc" @change="calcHeight">
              <option></option>
              <option value="0.0625">1/16</option>
              <option value="0.125">1/8</option>
              <option value="0.1875">3/16</option>
              <option value="0.25">1/4</option>
              <option value="0.3125">5/16</option>
              <option value="0.375">3/8</option>
              <option value="0.4375">7/16</option>
              <option value="0.5">1/2</option>
              <option value="0.5625">9/16</option>
              <option value="0.625">5/8</option>
              <option value="0.6875">11/16</option>
              <option value="0.75">3/4</option>
              <option value="0.8125">13/16</option>
              <option value="0.875">7/8</option>
              <option value="0.9375">15/16</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid pt-3 text-center" v-if="stepNumber === 1">
      <h3>Frame Creator</h3>

      <h5>Add Image</h5>

      <div class="row">
        <div
            class="col-md-6 offset-md-3 p-2"
            style="border: 16px solid #95c5c1"
        >
          <div
              ref="fileform"
              class="frame-creator_container w-100 d-inline-block mx-auto p-5"
              style="border: 3px dashed #95c5c1"
          >
            <h5 class="text-green">Drop File Here</h5>
            <h5>- OR -</h5>
            <button
                class="btn btn-secondary d-block mx-auto"
                @click.prevent="showFileChooser"
            >
              Select File
            </button>
            <button class="btn btn-link mt-2" @click.prevent="createBlankImage">
              Continue Without Image
            </button>
            <br/>
            <button
                v-if="isLoggedin"
                class="btn btn-link mt-2"
                @click.prevent="savedFrameModal"
            >
              Saved Frames
            </button>
          </div>
        </div>
      </div>

      <div class="mt-5 pt-4 pb-4">
        <img src="@/assets/images/banner-30years.png" class="img-fluid"/>
      </div>
    </div>

    <div class="row" :class="{ 'border': stepNumber === 2 }" style="height:1100px;"
         v-if="stepNumber === 1 || stepNumber === 2">
      <div class="col-4 mt-5" :class="{ 'd-none': stepNumber === 1 || stepNumber === 3 }">

        <ul class="no-bullets text-center"
            style="white-space: nowrap; width:200px; margin-left:auto; margin-right:auto;">
          <li><h5>Choose image from below</h5></li>
          <li>
            <button class="btn btn-secondary mt-2 mb-2" type="button" @click.prevent="createBlankImage('nature')">
              <strong>Nature</strong></button>
          </li>
          <li>
            <button class="btn btn-secondary mt-2 mb-2" type="button" @click.prevent="createBlankImage('places')">
              <strong>Places</strong></button>
          </li>
          <li>
            <button class="btn btn-secondary mt-2 mb-2" type="button" @click.prevent="createBlankImage('travel')">
              <strong>Travel</strong></button>
          </li>
          <li>
            <button class="btn btn-secondary mt-2 mb-2" type="button" @click.prevent="createBlankImage('buildings')">
              <strong>Buildings</strong></button>
          </li>
          <li>
            <button class="btn btn-secondary mt-2 mb-2" type="button" @click.prevent="createBlankImage('animals')">
              <strong>Animals</strong></button>
          </li>
        </ul>
      </div>
      <div class="col-8 mt-5" :class="{ 'd-none': stepNumber === 1 || stepNumber === 3 }">
        <cropper-control
            ref="cropper2"
            :stepNumber="stepNumber"
            :selectedFilters="selectedFilters"
            @setUserImage="setUserImage"
            @updateStep="updateStep"
            @setNewImage="setNewImage"
        ></cropper-control>
      </div>
    </div>

    <div class="container pt-3" v-if="stepNumber === 3">
      <h3 class="text-center mt-4">Frame Creator</h3>
      <div class="row mt-3">
        <div class="col col-md-3">
          <h5>Overview</h5>
          <div class="mt-3 mb-3">
            <div class="card">
              <div class="card-header label fw-bold">Frame</div>
              <div class="card-body">
                {{ selectedFilters.moulding.ManufCode }}
                <p class="mb-1">Moulding: {{ selectedFilters.moulding.Code }}</p>
                <p class="mb-1">
                  Collection: {{ selectedFilters.moulding.collection_name }}
                </p>
                <p class="mb-1">
                  Finish: {{ selectedFilters.moulding.finish_name }}
                </p>
                <p class="mb-1">Width: {{ selectedFilters.moulding.Width }}</p>
                <p class="mb-1">Rabbet: {{ selectedFilters.moulding.rabbet }}</p>
                <p class="mb-1">Style: {{ selectedFilters.moulding.style_name }}</p>
                <button class="btn btn-primary mb-3" @click.prevent="mouldingSelection">
                  <i class="bi bi-pencil-fill"></i>
                  Select Moulding
                </button>
                <button
                    v-show="selectedFilters.moulding.Code !== undefined"
                    class="btn btn-primary"
                    @click.prevent="removeMouldingSelection"
                >
                  <i class="bi bi-trash-fill"></i> Remove Moulding
                </button>
              </div>
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="card">
              <div class="card-header label fw-bold">Mat</div>
              <div class="card-body">

                <p class="mb-1">
                  Mat Description: {{ selectedFilters.mat.Description }}
                </p>
                <p class="mb-1">Mat Size: {{ selectedFilters.mat.Width }}</p>
                <p class="mb-1">Finish: {{ selectedFilters.mat.finish }}</p>
                <p class="mb-1">Rabbet: {{ selectedFilters.mat.rabbet }}</p>

                <div class="row g-0" v-show="selectedFilters.mat.Code != undefined">
                  <label class="form-label mt-2 mb-0">BORDERS</label>

                  <mat-box @selected="handleMatTBBorders" mType="TB" :filters="selectedFilters" :locked="isLocked"></mat-box>
                  <div class="mg-t" style="margin-top:20px; margin-bottom:20px; border-top:1px solid #adb5bd;"></div>
                  <mat-box @selected="handleMatLRBorders" mType="LR" :filters="selectedFilters" :locked="isLocked"></mat-box>
                  <!--
                  <div class="col-3">
                    <input
                        type="text"
                        v-model="selectedFilters.matTop"
                        class="form-control"
                        id="dimTop"
                        placeholder="0"
                    />
                    <small class="text-center d-block">Top</small>
                  </div>

                  <div class="col-2">
                    <span class="mt-2 d-block fw-bold text-center">X</span>
                  </div>

                  <div class="col-3">
                    <input
                        v-model="selectedFilters.matBottom"
                        type="text"
                        class="form-control"
                        id="dimBottom"
                        placeholder="0"
                    />
                    <small class="text-center d-block">Bottom</small>
                  </div>
                  -->
                </div>
                <!--
                <div
                    class="row g-0 mt-2"
                    v-show="selectedFilters.mat.Code != undefined"
                >
                  <div class="col-3">
                    <input
                        v-model="selectedFilters.matLeft"
                        type="text"
                        class="form-control"
                        id="dimLeft"
                        placeholder="0"
                    />
                    <small class="text-center d-block">Left</small>
                  </div>

                  <div class="col-2">
                    <span class="mt-2 d-block fw-bold text-center">X</span>
                  </div>

                  <div class="col-3">
                    <input
                        v-model="selectedFilters.matRight"
                        type="text"
                        class="form-control"
                        id="dimRight"
                        placeholder="0"
                    />
                    <small class="text-center d-block">Right</small>
                  </div>
                </div>
                -->

                <div
                    class="row g-0 mt-2"
                    v-show="selectedFilters.mat.Code != undefined"
                >
                  <div class="col-3">
                    <label for="matLock">
                      <input
                          type="checkbox"
                          name="matLock"
                          id="matLock"
                          v-model="isLocked"
                      />
                      Lock
                    </label>
                  </div>
                </div>

                <div
                    class="mt-3 mb-3"
                    v-show="selectedFilters.mat.Code != undefined"
                >
                  <div class="row g-0">
                    <label class="form-label mt-2 mb-0">MAT OPENING</label>

                    <mat-box @selected="handleMatOpen" mType="opening" :filters="selectedFilters"></mat-box>
                    <!--
                    <div class="col-3">
                      <input
                          type="text"
                          class="form-control"
                          id="dim1"
                          placeholder="0"
                          v-model="selectedFilters.matOpenWidth"
                      />
                      <small class="text-center d-block">Width</small>
                    </div>

                    <div class="col-2">
                      <span class="mt-2 d-block fw-bold text-center">X</span>
                    </div>

                    <div class="col-3">
                      <input
                          type="text"
                          class="form-control"
                          id="dim3"
                          placeholder="0"
                          v-model="selectedFilters.matOpenHeight"
                      />
                      <small class="text-center d-block">Height</small>
                    </div>
                    -->
                  </div>
                </div>

                <button class="btn btn-primary mb-1" @click.prevent="matSelection">
                  <i class="bi bi-pencil-fill"></i> Select Mat
                </button>
                <button
                    v-show="selectedFilters.mat.Code != undefined"
                    class="btn btn-primary"
                    @click.prevent="removeMatSelection">
                  <i class="bi bi-trash-fill"></i> Remove Mat
                </button>
              </div>
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="card">
              <div class="card-header label fw-bold">Glazing</div>
              <div class="card-body">
                <p class="mb-1">{{ selectedFilters?.glass?.display_name }}</p>
                <p class="mb-1">{{ selectedFilters?.glass?.Description }}</p>
                <button class="btn btn-primary mb-3" @click.prevent="glazingSelection">
                  <i class="bi bi-pencil-fill"></i> Select Glazing
                </button>
                <button
                    v-show="selectedFilters.glass.display_name !== undefined"
                    class="btn btn-primary"
                    @click.prevent="removeGlazingSelection"
                >
                  <i class="bi bi-trash-fill"></i> Remove Glazing
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-md-7 offset-md-1 text-center justify-content-center">
          <div class="row mt-3 text-center">
            <div class="col-12 text-center">
              {{
                'Width ' + toFraction(this.selectedFilters.fWidth) + ' X Height ' + toFraction(this.selectedFilters.fHeight)
              }}
              <div style="float: right;" v-html="savedLabel()"></div>
            </div>
          </div>
          <div
              class="border-boundary border border-dark"
              style="min-height: 500px; background-color: #f3f3f3; display: grid;"
          >

            <div class="block">
              <div class="table-container">
                <img
                    :src="builtFrame"
                    alt=""
                    :key="builtFrameKey"
                    :class="builtClass"
                    :style="imgStyle"
                />
              </div>
            </div>

          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
            <button class="btn btn-primary" @click.prevent="editImage">
              <i class="bi bi-pencil-fill"></i> Edit Image
            </button>
            <button class="btn btn-primary" v-if="isLoggedin && !noPurchase" @click.prevent="addToCart">
              <i class="bi bi-cart"></i> Add to Cart
            </button>
            <button
                v-if="isLoggedin"
                class="btn btn-primary"
                style="background-color:#FAE565; color:#000; border:1px solid #FAE565;"
                type="button"
                @click.prevent="saveDesign()"
            >
              <i class="bi bi-save"> Save</i>
            </button>
            <button
                v-if="isLoggedin && savedFrameId"
                class="btn btn-primary"
                style="background-color:#FAE565; color:#000; border:1px solid #FAE565;"
                type="button"
                @click.prevent="saveDesign('as')"
            >
              <i class="bi bi-save"> Save As</i>
            </button>
            <FCPrintout :selectedFilters="selectedFilters" :printNotes="printNotes" :builtFrame="builtFrame"></FCPrintout>
          </div>
          <div style="margin-top:5px;">
            <label for="pNotes">Notes:</label>
            <textarea name="pNotes" id="pNotes" cols="68" rows="5" style="width: 100%" v-model="printNotes"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div
        class="modal fade"
        id="selectionModal"
        tabindex="-1"
        aria-labelledby="selectionModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-header border-0">
              <h5 class="modal-title">{{ selectionModalOptions.title }}</h5>
              <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="container pt-3"
                   v-show="!selectionModalOptions.showMouldingSearch"
              >
                <div class="input-group">
                  <button
                      class="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    Item #
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          class="dropdown-item"
                          href="#"
                          onclick="event.preventDefault();"
                      >Action before</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#"
                      >Another action before</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider"/>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                  <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="selectionModalOptions.search"
                      aria-label="with two button addons"
                  />
                  <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click.prevent="searchItem"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
              <div class="container pt-3"
                   v-show="!selectionModalOptions.showMouldingSearch"
              >
                <h5 class="text-center">OR</h5>
                <h5 class="text-center">Select a Collection</h5>
              </div>
              <shop
                  ref="shop"
                  :searchOptions="selectionModalOptions"
                  @frameBuilder="frameBuilder"
                  v-show="selectionModalOptions.showMouldingSearch"
              ></shop>

              <div
                  class="cropper_inner"
                  v-show="
                  selectionModalOptions.section === 1 &&
                  !selectionModalOptions.showMouldingSearch
                "
              >
                <div class="row row-cols-5 g-2">
                  <div
                      class="col"
                      v-for="product in selectionModalOptions.products"
                      :key="product.id"
                  >
                    <a
                        href="#"
                        @click.prevent="
                        searchMoulding({ collection: [product.id] })
                      "
                    >
                      <div class="frame-thumb text-center shadow mx-auto">
                        <img
                            :src="collectionImg(product.id)"
                            class="rounded img-thumbnail border-0 h-100"
                            alt="..."
                        />
                      </div>
                      <p class="text-center mt-2">{{ product.name }}</p>
                    </a>
                  </div>
                </div>
              </div>
              <div
                  class="cropper_inner"
                  v-show="selectionModalOptions.section === 2"
              >
                section 2
              </div>
            </div>
            <div class="modal-footer border-0"></div>
          </div>
        </div>
      </div>
    </div>


    <!-- Back Modal-->

    <div
        class="modal fade"
        id="backModal"
        tabindex="-1"
        aria-labelledby="backModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="backModalLabel">Frame Creator</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                You have not saved your work. Do you want to leave and lose changes?
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click.prevent="confirmGoBack()">
              Yes
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              No
            </button>
          </div>
        </div>
      </div>
    </div>


    <div
        class="modal fade"
        id="savedFramesModal"
        tabindex="-1"
        aria-labelledby="savedFramesModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="savedFramesModalLabel">Saved Frames</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Date</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="frame in savedFramesData"
                  :key="frame.id"
                  style="cursor:pointer;"
              >
                <td @click.prevent="loadSavedFrame(frame.id)">{{ frame.id }}</td>
                <td @click.prevent="loadSavedFrame(frame.id)">{{ frame.name }}</td>
                <td @click.prevent="loadSavedFrame(frame.id)">{{ frame.fdate }}</td>
                <td>
                  <button class="btn btn-danger" @click.prevent="deleteSavedFrames(frame.id)">
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <!-- add more rows as needed -->
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
        class="modal fade"
        id="saveDesignModal"
        tabindex="-1"
        aria-labelledby="saveDesignModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveDesignModalLabel">Save Frame <span v-if="saveType === 'as'">As</span></h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col text-danger" v-if="savedFrameId && saveType !== 'as'">Changes will be overwritten</div>
            </div>
            <div class="mb-3">
              <label for="nameInput" class="form-label">Name</label>
              <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  v-model="saveDesignName"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
                type="button"
                class="btn btn-primary"
                id="saveButton"
                @click="saveFrameDesign"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <CartDetails ref="cartD" modalId="frameModal" @closeModal="closeFrameModal" @returnData="returnData"></CartDetails>
  </div>
</template>

<script>
import cropperControl from "@/components/cropperControl";
import {mapGetters} from "vuex";
import {Modal} from "bootstrap";
import shop from "@/views/shop.vue";

import {useToast} from "vue-toastification";
//import {reactive} from "vue";
import CartDetails from "@/components/Auth/CartDetails.vue";
import Fraction from "fraction.js";
import FCPrintout from "@/views/FCPrintout.vue";
import matBox from "@/components/matBox.vue";

export default {
  name: "FrameBuilderFE",
  setup() {
    // Get toast interface

    const toast = useToast();
    return {toast};
  },
  components: {
    matBox,
    cropperControl,
    shop,
    FCPrintout,
    CartDetails
  },
  computed: {
    ...mapGetters({
      filters: "frameBuilder/getBuiltFilters",
      availableFilters: "product/getFilterData",
      hasFrameBuilder: "frameBuilder/getFrameBuilderSessionId",
      hasCurrentBuild: "frameBuilder/hasCurrentBuild",
      savedFramesData: "frameBuilder/getSavedFrames",
      isLoggedin: "userAccess/isLoggedin",
      cartInitialState: "product/getInitialState",
      userData: 'userAccess/user'
    }),
    imgStyle() {
      return {
        width: this.width + "px",
        height: this.height + "px",
      };
    }
  },
  data() {
    const tW = 200;
    const tH = 300;
    return {
      loader: "dots",
      timeout: 10000, //ms
      color: "#00ab00",
      bgColor: "#4b4b4b",
      fullPage: true,
      canCancel: false,
      isLocked: true,
      noPurchase: false,
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      left: `calc( 50% - ${tW / 2}px)`,
      top: `calc(50% - ${tH / 2}px)`,
      height: tH,
      width: tW,
      maxW: 250,
      maxH: 250,
      minW: 100,
      minH: 100,
      fit: true,
      event: "",
      dragSelector: ".table-container",
      saveDesignModal: null,
      saveDesignName: "",
      dragAndDropCapable: false,
      files: [],
      selectionModal: null,
      savedModal: null,
      backModal: null,
      frameModal: null,
      outFile: "",
      outFileKey: 1,
      data: null,
      stepNumber: 1,
      imgSelection: true,
      File: [],
      inchToPixelOffset: 3,
      builtFrameKey: 1,
      builtFrame: "",
      savedFrameId: null,
      isSaved: false,
      saveType: '',
      backPath: '',
      stopReturn: false,
      runSetSaved: true,
      selectionModalOptions: {
        title: "",
        products: {},
        section: 1,
        search: "",
        showMouldingSearch: false,
      },
      newImage: '',
      orgImage: '',
      fWidthCalc: 20,
      fWidthDCalc: 0,
      fHeightCalc: 30,
      fHeightDCalc: 0,
      printNotes: '',
      selectedFilters: {
        moulding: {},
        fWidth: 20,
        fHeight: 30,
        fSize: 0,
        mat: {},
        glass: {},
        fillet: {},
        file: "",
        matTop: 3,
        matTopFrac: 0,
        matTopTotal: 3,
        matBottom: 3,
        matBottomFrac: 0,
        matBottomTotal: 3,
        matLeft: 3,
        matLeftFrac: 0,
        matLeftTotal: 3,
        matRight: 3,
        matRightFrac: 0,
        matRightTotal: 3,
        matOpenWidth: 0,
        matOpenHeight: 0,
        matOpenWidthFrac: 0,
        matOpenHeightFrac: 0
      },
      builtStyle: {},
      builtClass: {
        builtFrameImg: true,
      },
      imageContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
    };
  },
  watch: {
    stepNumber: function (newVal) {
      if (newVal === 3) {
        let self = this;
        setTimeout(function () {
          self.inputEvents();
        }, 1000);

        this.$store.dispatch("product/getFilters", {options: {}});
      }
    },
    "selectedFilters"() {
      if (this.runSetSaved) {
        this.isSaved = false
      }
    },
    "selectedFilters.matTop"(newVal) {
      this.checkLock(newVal);
    },

  },
  methods: {
    handleMatOpen(value) {
      this.selectedFilters.matOpenWidth = value.inputFromValue
      this.selectedFilters.matOpenWidthFrac = value.selectedFromValue
      this.selectedFilters.matOpenHeight = value.inputToValue
      this.selectedFilters.matOpenHeightFrac = value.selectedToValue
    },
    handleMatTBBorders(value) {
      this.selectedFilters.matTop = value.inputFromValue
      this.selectedFilters.matBottom = value.inputToValue
      this.selectedFilters.matTopFrac = value.selectedFromValue
      this.selectedFilters.matBottomFrac = value.selectedToValue

      this.selectedFilters.matTopTotal = (
          parseFloat(value.inputFromValue || 0) +
          parseFloat(value.selectedFromValue || 0)
      );

      this.selectedFilters.matBottomTotal = (
          parseFloat(value.inputToValue || 0) +
          parseFloat(value.selectedToValue || 0)
      );

    },
    handleMatLRBorders(value) {
      this.selectedFilters.matLeft = value.inputFromValue
      this.selectedFilters.matRight = value.inputToValue
      this.selectedFilters.matLeftFrac = value.selectedFromValue
      this.selectedFilters.matRightFrac = value.selectedToValue

      this.selectedFilters.matLeftTotal = (
          parseFloat(value.inputFromValue || 0) +
          parseFloat(value.selectedFromValue || 0)
      );

      this.selectedFilters.matRightTotal = (
          parseFloat(value.inputToValue || 0) +
          parseFloat(value.selectedToValue || 0)
      );

    },
    confirmGoBack() {
      this.isSaved = true
      this.backModal.hide();
      if (this.backPath !== '') {
        this.$router.push(this.backPath)
      } else {
        this.$router.go(-1)
      }
    },
    savedLabel() {
      return this.isSaved ? '<span class="badge bg-success mb-1" style="font-size: 14px;">Saved</span>' : '<span class="badge bg-danger mb-1 " style="font-size: 14px;">Not Saved</span>'
    },
    deleteSavedFrames(frameid) {
      this.$store.dispatch("frameBuilder/deleteSavedFrame", frameid)
          .then(() => {

          });
    },
    toFraction(frac) {
      let x = new Fraction(frac);
      return x.toFraction(true); // String "1 22/25"
    },
    startOver() {
      this.updateStep(1);
    },
    formatInput(value) {
      // Remove non-digit and non-decimal point characters
      const numericValue = value.replace(/[^\d.]/g, "");

      // Split the input into whole and decimal parts
      const parts = numericValue.split('.');
      let wholePart = parts[0];
      let decimalPart = parts[1];

      // Truncate the whole part to a maximum of 90
      if (numericValue !== null && (isNaN(numericValue) || numericValue > 90)) {
        wholePart = '90';
        return wholePart;
      }

      // Limit the decimal part to two decimal places
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Combine the whole and decimal parts
      if (decimalPart !== undefined) {
        return `${wholePart}.${decimalPart}`;
      } else {
        return wholePart;
      }
    },
    calcWidth() {
      this.fWidthCalc = this.fWidthCalc.toString().replace(/[^\d]/g, "");
      let c = (parseFloat(this.fWidthCalc) + parseFloat(this.fWidthDCalc))

      if (c !== null && (isNaN(c) || c > 90)) {
        this.selectedFilters.fWidth = 90
        this.fWidthCalc = 90
        this.fWidthDCalc = 0;
      } else {
        this.selectedFilters.fWidth = c;
      }
    },
    calcHeight() {
      this.fHeightCalc = this.fHeightCalc.toString().replace(/[^\d]/g, "");
      let c = (parseFloat(this.fHeightCalc) + parseFloat(this.fHeightDCalc))
      if (c !== null && (isNaN(c) || c > 90)) {
        this.selectedFilters.fHeight = 90
        this.fHeightCalc = 90
        this.fHeightDCalc = 0;
      } else {
        this.selectedFilters.fHeight = c;
      }
    },
    pullImage(category) {
      return new Promise((resolve, reject) => {
        let API_KEY = process.env.VUE_APP_PIXELBAY;

        category = category === '' ? 'nature' : category

        //let URL = "https://pixabay.com/api/?key=" + API_KEY + "&safesearch=true&per_page=3&category=nature&q=" + encodeURIComponent('red roses');
        let URL = "https://pixabay.com/api/?key=" + API_KEY + "&image_type=photo&safesearch=true&per_page=20&category=" + category;
        fetch(URL)
            .then(response => response.json())
            .then(data => {
              if (parseInt(data.totalHits) > 0) {
                var randomNumber = Math.floor(Math.random() * 20);
                resolve(data.hits[randomNumber].webformatURL)
              } else {
                reject('No hits');
              }
            })
            .catch(error => reject(error));
      });
    }
    ,
    async createBlankImage(category) {
      let image = await this.pullImage(category)
      this.newImage = image
      let self = this;
      this.getImageBlobData(image).then(blob => {
        // Use the image blob data as needed
        const file = new File([blob], "blue-image.png", {type: "image/png"});
        self.$refs.cropper2.setWaitImage(file);
      }).catch(error => {
        console.error('Error:', error);
      });
    },
    calculateFrameLength(width, height, frameWidth) {
      // Calculate the total width including frame
      const totalWidth = width + (2 * frameWidth);

      // Calculate the total height including frame
      const totalHeight = height + (2 * frameWidth);

      // Calculate the total length of the frame
      const frameLength = 2 * (totalWidth + totalHeight);

      return Math.ceil(frameLength / 12);
    },
    async closeFrameModal() {
      this.stopReturn = false
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: this.loader,
        opacity: 0.3,
      });


      if (this.selectedFilters.mat.Code !== undefined) {
        const loadMat = this.$refs.cartD.loadProductDetails(this.selectedFilters.mat.Code);
        await Promise.all([loadMat]);
      }
      if (this.selectedFilters.glass.Code !== undefined) {
        const loadGlass = this.$refs.cartD.loadProductDetails(this.selectedFilters.glass.Code);
        await Promise.all([loadGlass]);
      }
      loader.hide();
      this.toast.success("Items added to cart.");
      this.frameModal.hide();
    },
    returnData(data) {
      if (!this.stopReturn) {
        if (data.productType === 4) {
          const height = this.selectedFilters.fHeight;
          const width = this.selectedFilters.fWidth;
          const frameWidth = this.selectedFilters.moulding.Width !== null ? this.selectedFilters.moulding.Width : 1;
          const totalLength = this.calculateFrameLength(width, height, frameWidth)

          data.productSelection.length = totalLength
        } else if (data.productType === 3 || data.productType === 1) {
          //MAT 3, Glass 1
          data.productSelection.quantity = 1
        } else {
          data.productSelection.quantity = 1
        }

        this.$store.dispatch('cart/addToCart', {Code: this.Code, cartdata: data}).then(() => {
          this.$emit('closeModal')
        })
      }
    },
    async addToCart() {
      if (this.selectedFilters.moulding.Code !== undefined) {
        this.stopReturn = true

        const loadMoulding = this.$refs.cartD.loadProductDetails(this.selectedFilters.moulding.Code);
        await Promise.all([loadMoulding]);

        const height = this.selectedFilters.fHeight;
        const width = this.selectedFilters.fWidth;
        const frameWidth = this.selectedFilters.moulding.Width !== null ? this.selectedFilters.moulding.Width : 1;
        this.$refs.cartD.productSelection.length = this.calculateFrameLength(width, height, frameWidth)

        this.frameModal.show();
      }
    },
    collectionImg(id) {
      return process.env.VUE_APP_CDN + "/collections/" + id + ".jpg";
    },
    loadSavedFrame(frameid) {
      this.$store
          .dispatch("frameBuilder/getSavedFrames", {
            options: {id: frameid, getFramedata: true},
          })
          .then((data) => {
            this.savedModal.hide();
            let newdata = JSON.parse(atob(data[0].framedata));
            Object.assign(this.$data, newdata);
            this.runSetSaved = false
            this.savedFrameId = frameid;
            this.isSaved = true
            let self = this
            setTimeout(function () {
              self.runSetSaved = true
            }, 1000)
            this.initPage();
          });
    }
    ,
    checkLock(value) {
      if (this.isLocked) {
        this.selectedFilters.matTop = value;
        this.selectedFilters.matBottom = value;
        this.selectedFilters.matLeft = value;
        this.selectedFilters.matRight = value;
      }
    }
    ,
    eHandler(data) {
      this.getImageSizeFromDataURI(this.selectedFilters.file)
          .then(({width, height}) => {
            this.width = data.width;
            this.height = data.height;
            this.left = (document.querySelector(".border-boundary").clientWidth / 2) - (width / 2) + (height > width ? 25 : 0);
            this.top = (height / 2) / 2 + 15;
            this.event = data.eventName;
          })
          .catch((error) => {
            console.error(error.message);
          });

    }
    ,
    getImageSizeFromDataURI(dataURI) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };

        image.onerror = function () {
          reject(new Error('Failed to load the image.'));
        };

        image.src = dataURI;
      });
    }
    ,
    searchItem() {
      this.selectionModalOptions.showMouldingSearch = true;
      let data = {Code: [this.selectionModalOptions.search]};
      this.$refs.shop.search(data);
    }
    ,
    determineDragAndDropCapable() {
      let div = document.createElement("div");

      return (
          ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
          "FormData" in window &&
          "FileReader" in window
      );
    }
    ,
    searchMoulding(data) {
      this.selectionModalOptions.showMouldingSearch = true;
      this.$refs.shop.search(data);
    }
    ,
    mouldingSelection() {
      this.selectionModalOptions.title = "Mouldings";
      this.selectionModalOptions.products = this.availableFilters.collection;
      this.selectionModalOptions.showMouldingSearch = true;
      this.$refs.shop.runWatch = false;
      this.$refs.shop.filters.type = [];
      this.$refs.shop.runWatch = true;
      this.$refs.shop.isFrameBuilder = true;
      this.$refs.shop.filters.type.push(4);
      this.$refs.shop.filters.isFC = true;
      this.selectionModal.show();
    }
    ,
    matSelection() {
      this.selectionModalOptions.title = "Mats";
      this.selectionModalOptions.products = this.availableFilters.collection;
      this.selectionModalOptions.showMouldingSearch = true;
      this.$refs.shop.isFrameBuilder = true;
      this.$refs.shop.runWatch = false;
      this.$refs.shop.filters.type = [];
      this.$refs.shop.runWatch = true;
      this.$refs.shop.filters.type.push(3);
      this.$refs.shop.filters.isFC = true;
      this.selectionModal.show();
    }
    ,
    removeMouldingSelection() {
      this.selectedFilters.moulding = {};
      this.frameBuilder();
    }
    ,
    removeMatSelection() {
      this.selectedFilters.mat = {};
      this.frameBuilder();
    }
    ,
    removeGlazingSelection() {
      this.selectedFilters.glass = {};
      this.frameBuilder();
    }
    ,
    glazingSelection() {
      this.selectionModalOptions.title = "Glazing";
      this.selectionModalOptions.showMouldingSearch = true;
      this.$refs.shop.runWatch = false;
      this.$refs.shop.isFrameBuilder = true;
      this.$refs.shop.filters.type = [];
      this.$refs.shop.runWatch = true;
      this.$refs.shop.filters.type.push(1);
      this.$refs.shop.filters.isFC = true;
      //this.$refs.shop.loadInfScroll()
      this.selectionModal.show();
    }
    ,
    savedFrameModal() {
      this.$store.dispatch("frameBuilder/getSavedFrames", {}).then(() => {
      });
      this.savedModal.show();
    }
    ,
    frameBuilder(filters) {
      this.selectedFilters = {...this.selectedFilters, ...filters};
      this.buildFrame();
      this.selectionModal.hide();
    }
    ,
    buildFrame() {
      //let options = this.selectedFilters;
      let options = { ...this.selectedFilters };
      options.matTop = options.matTopTotal
      options.matBottom = options.matBottomTotal
      options.matLeft = options.matLeftTotal
      options.matRight = options.matRightTotal
      this.$store
          .dispatch("frameBuilder/buildFrame", {options})
          .then((data) => {
            this.builtStyle = {
              "max-width": data.imgDetail.width + "px",
              "max-height": data.imgDetail.height + "px",
            };

            this.width = data.imgDetail.width;
            this.height = data.imgDetail.height;

            this.builtFrame = data.outFile;
            this.builtFrameKey = this.$moment();
          });
    },
    saveDesign(type) {
      this.saveType = type === 'as' ? 'as' : ''
      this.saveDesignModal.show();
    },
    saveFrameDesign() {
      if (this.saveDesignName === "") {
        alert("Name is required");
        return;
      }

      let allData = btoa(JSON.stringify(this.$data));

      if (this.saveType === 'as') {
        this.savedFrameId = ''
      }

      this.$store
          .dispatch("frameBuilder/saveDesignFrame", {
            options: {
              framedata: allData,
              name: this.saveDesignName,
              id: this.savedFrameId,
            },
          })
          .then(rez => {
            this.savedFrameId = rez.framedataid
            this.isSaved = true
            this.saveDesignModal.hide();
          });
    }
    ,
    setUserImage(imgData) {
      this.stepNumber = 3;
      this.imgSelection = false;
      this.selectedFilters.file = imgData;
      this.orgImage = imgData;

      this.buildFrame();
      //this.mouldingSelection();
      //this.$store.dispatch('frameBuilder/updateCurrentBuild',true)
    }
    ,
    showFileChooser() {
      this.$refs.cropper2.showFileChooser();
    },
    setNewImage(e) {
      let file = '';
      if (e instanceof File) {
        file = e;
      } else if (e.target.files === undefined) {
        file = e.dataTransfer.files[0];
      } else {
        file = e.target.files[0]
      }
      this.newImage = file;
    },
    editImage() {
      this.updateStep(2);

      this.imgSelection = true;
      let image = this.newImage
      let self = this;
      if (image instanceof File) {
        setTimeout(function () {
          self.$refs.cropper2.setWaitImage(image);
        }, 500)
      } else {
        image = this.orgImage
        this.getImageBlobData(image).then(blob => {
          // Use the image blob data as needed
          const file = new File([blob], "blue-image.png", {type: "image/png"});
          self.$refs.cropper2.setWaitImage(file);
        }).catch(error => {
          console.error('Error:', error);
        });
      }
    },
    updateStep(step) {
      this.stepNumber = step;
      if (step === 3) {
        console.log("update the", step);
        //this.$store.dispatch('frameBuilder/updateCurrentBuild',true)
      }
    }
    ,
    inputEvents() {
      const elements = document.querySelectorAll(
          "#dimTop, #dimBottom, #dimLeft, #dimRight"
      );
      let self = this;
      elements.forEach(function (element) {
        element.addEventListener("keyup", function () {
          // code to be executed on click
          const regex = /^\d*\.?\d*$/;
          if (!regex.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9.]/g, "");
          } else {
            self.buildFrame();
          }
        });
      });

      const elementsFrac = document.querySelectorAll(
          "#dimTopFrac, #dimBottomFrac, #dimLeftFrac, #dimRightFrac"
      );
      elementsFrac.forEach(function (elementF) {
        elementF.addEventListener("change", function () {
          // code to be executed on click
          const regex = /^\d*\.?\d*$/;
          if (!regex.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9.]/g, "");
          } else {
            self.buildFrame();
          }
        });
      });
    },
    getImageBlobData(url) {
      return fetch(url)
          .then(response => response.blob())
          .then(blob => blob);
    },
    initPage() {
      this.selectionModal = new Modal(document.getElementById("selectionModal"));
      this.savedModal = new Modal(document.getElementById("savedFramesModal"));
      this.saveDesignModal = new Modal(document.getElementById("saveDesignModal"));
      this.backModal = new Modal(document.getElementById("backModal"));
      let self = this
      setTimeout(function () {
        self.frameModal = new Modal(document.getElementById("frameModal"));
      }, 1000)
    },
  },
  beforeRouteLeave(to, from, next) {
    // Check if back button is pressed

    if (this.stepNumber === 3 && !this.isSaved) {
      // Prevent default behavior
      this.backPath = to.path
      this.backModal.show();
      next(false)
      // Optionally, you can perform custom actions here
    } else {
      // Allow navigation to proceed
      next()
    }
  },
  mounted() {
    this.$store.dispatch("frameBuilder/initBuilderId");
    this.$store.dispatch("frameBuilder/buildFilters");

    this.initPage();

    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
                evt,
                function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }.bind(this),
                false
            );
          }.bind(this)
      );

      this.$refs.fileform.addEventListener(
          "drop",
          function (e) {
            this.$refs.cropper2.setWaitImage(e);
          }.bind(this)
      );
    }
    if (this.hasCurrentBuild) {
      this.stepNumber = 3;
    }

    let activeAccount = this.userData?.accounts?.[0]?.ActiveType
    let terms = this.userData?.accounts?.[0]?.terms
    if(activeAccount === 'N' || String(terms).toLowerCase() === 'do not sell' || String(terms).toLowerCase() === 'credit hold'){
      this.noPurchase = true
    }

  }
  ,
}
;
</script>

<style scoped>

.no-bullets {
  list-style: none;
}

.frame-thumb {
  height: 100px;
}

.container {
  /*border: 1px solid red;*/
}

.builtFrameImg {
  width: 100%;
}

form {
  display: block;
  height: 400px;
  width: 400px;
  background: #ccc;
  margin: auto;
  margin-top: 40px;
  text-align: center;
  line-height: 400px;
  border-radius: 4px;
}

/************/

#block1 {
  border: solid black 1px;
  height: 300px;
  width: 300px;
  display: inline-block;
  float: left;
}

.resizable {
  background-position: top left;
  width: 150px;
  height: 150px;
  padding: 0;
  /*
    border: 1px solid #003eff;
    background: #007fff;
    */
  font-weight: normal;
  color: #ffffff;
  position: relative;
}

.table-block {
  display: table;
}

.table-row {
  display: table-row;
  text-align: center;
}

.table-cell {
  width: 50%;
  display: inline-block;
}

.table-input {
  width: 50px;
}

.drag-container-1,
.drag-container-2 {
  width: 100%;
  height: 20px;
  background: red;
  color: white;
  text-align: center;
  cursor: pointer;
}

.table-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block {
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card:hover {
  overflow: hidden;
  transform: none;
}
</style>

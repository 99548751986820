import { createStore } from 'vuex'
import userAccess from './modules/login'
import frameBuilder from "@/store/modules/framebuilder";
import user from "@/store/modules/user";
import cart from "@/store/modules/cart"
import product from "@/store/modules/products"
import orders from "@/store/modules/orders";
import Accounts from "@/store/modules/accounts";
import axios from "axios";
import router from '@/router'

axios.interceptors.response.use(response => {

  //check terms on account
  if(response?.data?.data?.[0]?.accounts?.[0]?.terms === 'CREDIT HOLD'){
    router.push({name: 'AccountMsg',
                 params: {msgHeading: 'Account Information',
                          msg: 'Please contact accounts payable or customer service. 800-640-2467'
                }})
  }

  return response;
}, error => {
  if (error.response.status === 401) {
    //place your reentry code
    console.log('401 here');
    localStorage.clear()
    location.href='/';
  }
  return error;
});
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userAccess,
    frameBuilder,
    user,
    cart,
    product,
    orders,
    Accounts
  }
})

<template>
  <!--
  <div>
    where to buy
    <input type="text" name="zip" v-model="form.zip"><br>
    <input type="text" name="miles" v-model="form.miles"><br>
    <button @click="searchWTB">Search</button>
  </div>
  -->
  <div class="container">

    <div class="row">

      <h4 class="fw-bold mb-3 mt-4 pt-2">Where to Buy</h4>
      <div class="col-xs-12 col-lg-7">

        <form class="row product__form mt-3 mb-4">
          <div class="col-12 col-sm-4">
            <label class="form-label" for="autoSizingInput">Enter your ZIP Code</label>
            <input type="text" class="form-control mb-3" id="autoSizingInput" placeholder="EX: 12345"
                   v-model="form.zip">
          </div>
          <div class="col-12 col-sm-4">
            <label class="form-label" for="autoSizingSelect">Search Within</label>
            <select class="form-select mb-3" id="autoSizingSelect" v-model="form.miles">
              <option value="1">1 mi</option>
              <option value="5">5 mi</option>
              <option value="10">10 mi</option>
              <option value="15">15 mi</option>
              <option value="20">20 mi</option>
              <option value="25">25 mi</option>
              <option value="50">50 mi</option>
            </select>
          </div>

          <div class="col-12 col-sm-auto">
            <button type="button" class="btn btn-secondary btn-lg fw-bold" @click="searchWTB" style="margin-top: 26px;">
              Search
            </button>
          </div>
        </form>

        <div class="location-results mt-4" v-if="hasWTB">

          <h5 class="mt-4 fw-semibold">Retailers Near {{ form.zip }}</h5>

          <ul class="location-results_list mb-5">
            <li class="d-flex justify-content-between" v-for="shop in wtbData" :key="shop.Acctnum"
                @click="displayInfoW(shop,$event)"
            >
              <div class="location_name d-flex justify-content-start align-items-center">
                <span class="number">{{shop.row}}</span>
                <span class="fw-semibold">{{ shop.Company }}</span>
              </div>

              <div class="location_miles d-flex align-items-center">
                <span>{{ shop.distance.toFixed(2) }} mi<i class="bi bi-cursor-fill text-green ms-2"></i></span>
              </div>

            </li>
          </ul>

        </div>
      </div>

      <div class="col-xs-12 col-lg-5">
        <div class="mb-4">
          <GoogleMap ref="mapRef" api-key="AIzaSyBOghWDKUL8gKrNMSIffyra6kVz6fIY2S0" style="width: 100%; height: 680px"
                     :center="center" :zoom="8">
            <InfoWindow v-if="showInfoWindow" :options="{ position:infoPos, content: infocontent }"/>
            <MarkerCluster>
              <Marker v-for="(location, i) in locations" :options="{ position: location }" :key="i">
              </Marker>
            </MarkerCluster>
          </GoogleMap>
          <!--
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.2722805461394!2d-83.74487388410452!3d41.71464307923549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883c7d21897ee8cb%3A0xa9db1b98236e321e!2sFrameworks%20Art%20and%20Frame!5e0!3m2!1sen!2sus!4v1672463917086!5m2!1sen!2sus" width="100%" height="680px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          -->
        </div>
      </div>

    </div>


    <div class="d-grid gap-2 col-lg-3 mx-auto empty-state text-center mb-4 d-none">
      <h5>Your Shopping Cart is Empty</h5>
      <button type="button" class="btn btn-secondary">Browse the Shop</button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ref} from "vue";
import {GoogleMap, Marker, MarkerCluster, InfoWindow} from "vue3-google-map";

export default {
  name: "whereToBuy",
  components: {GoogleMap, Marker, MarkerCluster, InfoWindow},
  setup() {
    const mapRef = ref(null)
    const center = ref(null)
    const locations = ref([])
    const infocontent = ref(null)
    const infoPos = ref(null)
    const showInfoWindow = ref(false)


    return {center, locations, infocontent, showInfoWindow, mapRef, infoPos}
  },
  data() {
    return {
      hasWTB: false,
      tableLoaded: true,
      counter: 0,
      form: {
        zip: '',
        miles: 50
      }
    }
  },
  computed: {
    ...mapGetters({
      'wtbData': 'product/getWTBData',
      'wtbCenter': 'product/getWTBCenter'
    }),
  },
  methods: {
    displayInfoW(shop,event) {

      let els = document.querySelectorAll('.location-results_list > *');
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected');
      }

      event.currentTarget.classList.add("selected");
      this.showInfoWindow = true;
      this.infocontent = '<span style="white-space: nowrap; font-weight: bold;">'+shop.Company+'</span><br>'+shop.Address1+'<br>'+shop.City+', '+shop.State+' '+shop.Zip;
      this.infoPos = {lat: parseFloat(shop.latitude), lng: parseFloat(shop.longitude)}
    },
    searchWTB() {
      this.counter = 0
      this.$store.dispatch('product/getWTBData', {options: this.form}).then(() => {

        this.hasWTB = true
        this.center = {'lat': this.wtbCenter[0], 'lng': this.wtbCenter[1]}
        let newLocations = [];
        Object.keys(this.wtbData).forEach(key => {
          this.counter++
          this.wtbData[key].row = this.counter
          newLocations.push({
            'lat': parseFloat(this.wtbData[key].latitude),
            'lng': parseFloat(this.wtbData[key].longitude)
          })
        });
        this.locations = newLocations
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
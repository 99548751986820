<template>
  <!-- Modal -->
  <div class="modal fade" style="z-index: 10000;" id="canNotBuyModal" tabindex="-1" aria-labelledby="canNotBuyModalLabel" aria-hidden="true" data-mdb-backdrop="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="canNotBuyModalLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>You are unable to place orders at this time contact customer service at 800-640-2467</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "canNotBuy",
}
</script>

<style scoped>

</style>